.navigation {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.navigation li a {
  border: 0;
  border-bottom: 5px solid var(--clr-off-white);
  background-color: inherit;
  text-align: center;
  padding: 0.5rem 2rem;
  background-color: rgba(0, 0, 0, 0.1);
  color: inherit;
  transition: 0.5s;
  font-size: 1rem;
  display: block;
}

.navigation li a.active {
  background-color: var(--clr-white) !important;
  color: var(--clr-primary);
  border-bottom-color: var(--clr-primary);
}
