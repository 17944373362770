.hr {
  width: 100px;
  border-bottom: 2px solid var(--clr-primary) !important;
  margin-block: 10px;
}

.details form,
.employeeDetails {
  display: flex;
  flex-direction: column;
}

.employeeDetails:nth-child(1) {
  margin-bottom: 20px;
}

.employeeDetails label,
.infoForm label {
  margin-bottom: 10px;
  font-size: 1rem;
  font-weight: 500;
}

.infoForm label span {
  color: var(--clr-red);
  font-weight: 700;
}

.employeeDetails input,
.employeeDetails select {
  border: 1px solid #ccc;
  width: 100%;
  background-color: #eee;
  border-radius: 0 6px 6px 0 !important;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  outline: none;
  text-align: center !important;
}

.employeeDetails select {
  border-radius: 6px !important;
}

.subheading3 {
  margin-block: 30px;
}

.infoForm {
  display: flex;
  flex-direction: column;
  margin-block: 30px;
}

.subheading4 {
  font-size: 1.2rem;
  margin-bottom: 0;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  margin-top: 20px;
}

.inputGroup input,
.inputGroup select,
.inputGroup textarea {
  border: 1px solid #ccc;
  outline: none;
  border-radius: 6px !important;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  background-color: inherit;
}

.inputGroup input::placeholder {
  font-size: 0.8rem;
}

.employee_id input {
  border-radius: 0 6px 6px 0 !important;
  text-align: center !important;
}

.employee_id input:disabled {
  background-color: rgba(0, 0, 0, 0.02);
  color: rgba(0, 0, 0, 0.88);
}

/* .inputGroup textarea {
  height: 150px;
  max-height: 150px;
  min-height: 150px;
} */

/* .saveBtn {
  border: 0;
  cursor: pointer;
  color: var(--clr-white);
  background-color: var(--clr-primary);
  border-radius: 6px;
  padding: 0.5rem 3rem;
  margin-top: 30px;
  align-self: flex-end;
  display: block;
} */

.formBtn {
  margin-block: 20px;
  background-color: var(--clr-primary);
  color: var(--clr-white);
  border: none !important;
  align-self: flex-end;
  padding: 0.5rem 1rem !important;
}

.formBtn:hover {
  background-color: var(--clr-primary);
  color: var(--clr-white) !important;
  transform: scale(0.98);
}
