.sidebarContainer {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  width: 20rem;
  background-color: var(--clr-primary);
  position: static;
  height: 150vh; 
  top: 0;
  bottom: 0;
  padding: 2.5rem 1.5rem;
  overflow-y: scroll;
  transition: var(--transition-default);
  transform: translateX(0);
  z-index: 9999;
  grid-column: 1;
}

.sidebarToggle {
  transform: translateX(-100%);
  transition: var(--transition-default);
}

.topbar {
  height: 5rem;
  background-color: #efefef;
  position: fixed;
  left: 20rem;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  transition: var(--transition-default);
  z-index: 50;
}

.topbarToggle {
  height: 5rem;
  background-color: #efefef;
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  transition: var(--transition-default);
}

.navWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 100%;
}

.topbarAvatar {
  height: 4rem;
  width: 4rem;
  max-width: 4rem;
  max-height: 4rem;
  overflow: hidden;
  cursor: pointer;
}

.rightsidebar {
  width: 6rem;
  position: fixed;
  top: 5.3rem;
  right: 0;
  bottom: 0;
  background-color: #efefef;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.externalLinks {
  width: 100%;
  padding: 0.8rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  font-weight: 500;
  border-bottom: 4px solid #ffffff;
  text-align: center;
}

.companyInfo {
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  height: 50px;
}

.companyLogo {
  border: 1px solid var(--clr-white);
  border-radius: 50%;
  aspect-ratio: 1;
  background-color: var(--clr-white);
  padding: 0.2rem;
  width: 50px;
  height: 50px !important;
  object-fit: contain;
}

.companyLogo img {
  border-radius: 50%;
  width: 100%;
  aspect-ratio: 1;
  width: 50px;
  /* height: 50px; */
}

.selected {
  color: var(--clr-primary) !important;
  background-color: var(--clr-white) !important;
  font-weight: bold !important;
}

.navLinkWrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.navLinkGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.navLinkGroup a {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 1rem;
  width: 100%;
  border-radius: 5px;
  color: var(--clr-white);
  transition: 0.3s all;
}

.navLinkGroup a:hover {
  border-radius: 5px;
  color: var(--clr-white);
  color: var(--clr-primary) !important;
  background-color: var(--clr-white) !important;
}

.navLinkGroup a span {
  font-size: 1.2rem;
  font-weight: 500;
}

.navLinkGroupHeader {
  color: #bdbdbd;
}

.disabled {
  color: #bdbdbd !important;
  cursor: not-allowed;
  pointer-events: none; /* Prevent all interactions */
}


@media (max-width: 1200px) {
  .sidebarContainer {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    width: 20rem;
    background-color: var(--clr-primary);
    position: fixed;
    height: 150vh; 
    top: 0;
    bottom: 0;
    padding: 2.5rem 1.5rem;
    overflow-y: scroll;
    transition: var(--transition-default);
    transform: translateX(-100%);
    z-index: 9999;
    grid-column: 1;
  }
  
  .sidebarToggle {
    transform: translateX(0);
    transition: var(--transition-default);
  }

  .topbar {
    position: fixed;
    left: 0rem;
    z-index: 50;
  }
  
  .topbarToggle {
    position: fixed;
    left: 0rem;
    right: 0;
  }

  .sidebarHeader{
    display: flex;
    justify-content: space-between;
    color:  var(--clr-white);
  }
}