/* general

.calendar {
    margin: 0 auto 20px;
    width: 80%;
  }
  
  .calendar .wrap {
    padding: 0 10px;
  }
  
  .currentYear {
    font-size: 32px;
    line-height: 1;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    text-align: center;
    color: #000000;
    padding: 0;
    margin: 0 auto 50px;
  } */

/* controls  */
/*
  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 0px 0 50px;
  }
  
  .controls .btn {
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    text-align: center;
    color: #ffffff;
    border: none;
    background-color: #000000;
    padding: 13px 20px;
    margin: 0;
    cursor: pointer;
  }
  */
/* year  */
/*
  .year {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin: 0 -10px;
  }
  */
/* months */
/*
  .month {
    width: 25%;
    padding: 0 10px;
    margin: 0 0 20px;
    box-sizing: border-box;
  }
  
  .singleCol .month {
    width: 100%;
  }
  
  .twoCol .month {
    width: 50%;
  }
  
  .monthName {
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    text-align: center;
    color: #000000;
    padding: 0;
    margin: 0 auto 20px;
  }
  
  .month .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  */
/* days */

/* .day { */
/* width: 14.166666666666667%; */
/*
    width: 13%;
    text-align: center;
    padding: 6px;
    box-sizing: border-box;
    font-size: 12px;
  }
  
  .dayOfTheWeek .day {
    font-weight: 800;
  }
  
  .booked {
    background-color: #000000;
    color: #ffffff;
  }
  
  .isLateCheckout {
    position: relative;
    background-color: transparent;
    overflow: hidden;
    color: #ffffff;
  }
  
  .isLateCheckout > span {
    background: white;
    color: #000000;
    padding: 0px 3px;
  }
  
  .isLateCheckout > span::after {
    content: '';
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    background: rgba(248, 80, 50, 1);
    background:linear-gradient(to bottom right,#000000 50%,transparent 50%);
    z-index: -1;
  }
  */
/* key */
/*
  .key {
    display: none;
    flex-direction: row;
    margin: 0 absolute
  }
  
  .key .type {
    margin: 0 10px 0 0;
  }
  
  .key .type span {
    position: relative;
    display: flex;
    line-height: 30px;
  }
  
  .key .type span::before {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 10px 0 0;
  }
  
  .bookedType span::before {
    background: #000000;
  }
  
  .lateCheckoutType span::before {
    background: rgba(248, 80, 50, 1);
    background:linear-gradient(to bottom right,#000000 50%,transparent 50%);
  }
  */
/* responsive */
/*
  @media (max-width: 980px) {
    .month {
      width: 50%;
    }
  }
  
  @media (max-width: 768px) {
    .month,
    .twoCol .month {
      width: 100%;
    }
  } 
  */

.annualLeave {
  padding-bottom: 3rem;
}
.annualTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.annualTop button {
  background-color: rgba(205, 216, 255, 1);
  min-width: max-content;
  padding: 5px 8px;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid rgba(0, 0, 0, 0.35);
}
.annualHead,
.annual_body h4 {
  text-transform: capitalize;
  /* font-size: 20px;
    line-height: 24.2px;
    font-weight: 600; */
}

/* .annual_body p{
    font-size: 10px;
    line-height: 12.1px;
    font-weight: 400;
  } */
.annualMain {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
}
.calendar_wrapper {
  position: relative;
  height: 350px;
}
.clear_calendar {
  position: absolute;
  right: 30px;
  font-size: 20px;
  color: red;
}
.Calendar {
  margin-top: 2rem;
}
.Calendar h4 {
  margin-bottom: 1rem;
}
.react-calendar {
  border-radius: 10px;
  height: 100%;
  /* position: inherit; */
  /* width: 300px; */
  /* font-family: inherit; */
}

.react-calendar__navigation__label {
  background-color: red;
}
.react-calendar abbr {
  text-decoration: none !important;
}
.react-calendar__tile abbr {
  font-size: 12px;
}
.react-calendar__navigation__label__labelText {
  position: absolute;
  left: 35%;
}
.react-calendar button {
  font-weight: 600;
}
.react-calendar button:disabled {
  cursor: auto !important;
  background-color: inherit !important;
}
.react-calendar button:disabled:hover {
  background-color: #fff !important;
  background-color: inherit !important;
}
.user-calendar button:disabled {
  background-color: #ccc !important;
}
.leave_submit_div {
  text-align: end;
  margin-top: 2rem;
}
.leave_submit_button {
  background-color: var(--clr-primary);
  width: 90px;
  height: 40px;
  padding: 13px, 27px, 13px, 27px;
  color: #fff;
  border-radius: 5px;
}
.disabled-tile {
  color: red; /* Change the text color for disabled tiles */
  font-weight: bold;
  font-style: italic;
}
