.module {
  height: 15vh;
  min-height: 120px;
  border: 1px solid var(--clr-off-white);
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  position: relative;
}

.moduleImage {
  height: 100%;
  display: flex;
  align-items: center;
}

.moduleImage img {
  width: 150px;
  max-width: 100%;
  display: block;
  padding: 1rem;
}

.heading {
  font-size: 16px;
  color: var(--clr-black);
  width: 40%;
  margin: 0;
}

.button {
  position: absolute;
  top: 5px;
  right: 10px;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 5px;
}

.button:hover {
  background-color: transparent !important;
  opacity: .9;
}

.button svg {
  font-size: 1.1rem;
  /* color: var(--clr-primary); */
}

@media (max-width: 768px) {
  .module {
    height: 22vh;
    padding: 1.5rem;
  }

  .heading {
    font-size: 14px;
    width: 50%;
  }
}
