/* @media (min-width: 768px) { */
.onboarding {
  display: grid;
  grid-template-columns: 300px repeat(11, 1fr);
  height: 100vh;
}

.onboarding__nav {
  background-color: rgba(247, 247, 250, 1);
}

.onboarding__nav h3 {
  width: 70%;
}

.onboarding-nav {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.onboarding-nav li a,
.onboarding-nav li p {
  border: 0;
  border-bottom: 5px solid var(--clr-primary);
  background-color: inherit;
  text-align: center;
  padding: 0.5rem 2rem;
  color: var(--clr-white);
  transition: 0.5s;
  font-size: 1rem;
  display: block;
}

.onboarding-nav li a.active {
  background-color: var(--clr-white) !important;
  color: var(--clr-primary);
  border-bottom-color: var(--clr-primary);
}

.back {
  color: var(--clr-black);
  font-size: 1rem !important;
  font-weight: 500;
}

.back svg {
  font-size: 1.2rem;
}

.back:hover {
  color: var(--clr-primary);
}

.onboarding__navlinks {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.onboarding__navlinks a {
  font-size: 1.2rem !important;
  display: flex;
  align-items: center;
  padding: 2rem 1.5rem;
  gap: 7px;
  color: var(--clr-black);
  transition: 0.5s ease-in-out;
}

.onboarding__navlinks a svg {
  font-size: 1.5rem;
}

.onboarding__navlinks a:hover {
  background-color: rgba(20, 20, 112, 0.04);
  color: var(--clr-black);
}

.onboarding__navlinks a.active {
  color: var(--clr-black);
  background-color: rgba(20, 20, 112, 0.12);
}

.onboarding__content {
  grid-column: span 11;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
}

.how-it-works {
  margin-bottom: 50px;
}

.how-it-works > .row > .col-2 {
  text-align: center;
}

.how-it-works .number {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  margin-block: 1rem;
  position: relative;
  z-index: 100;
}

.how-it-works .number:not(.last)::after {
  content: "";
  width: 250px;
  border-top: 1px solid var(--clr-primary-fd);
  position: absolute;
  left: 50px;
  z-index: 4;
}

.how-it-works > .row > .col-2 p {
  max-width: 70%;
  margin-inline: auto !important;
  font-weight: 600;
}

.how-it-works .row .col-2:nth-child(1) .number {
  background-color: rgba(218, 177, 177, 1);
}
.how-it-works .row .col-2:nth-child(2) .number {
  background-color: rgba(177, 218, 188, 1);
}
.how-it-works .row .col-2:nth-child(3) .number {
  background-color: rgba(218, 216, 177, 1);
}
.how-it-works .row .col-2:nth-child(4) .number {
  background-color: rgba(177, 191, 218, 1);
}
.how-it-works .row .col-2:nth-child(5) .number {
  background-color: rgba(217, 177, 218, 1);
}
.how-it-works .row .col-2:nth-child(6) .number {
  background-color: rgba(218, 177, 177, 1);
}

.leaveManagement_overview_tile{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: center; */
  gap: 20px;
}
.insight_leave{
  flex-basis: 23%;
  height: 123px;
  padding: 1rem;
  border-radius: 8px;
}
.insight_leave:nth-child(1){
  background-color: rgba(7, 27, 187, 0.12);
}

.insight_leave:nth-child(2){
  background-color: rgba(35, 113, 205, 0.19);
}

.insight_leave:nth-child(3){
  background-color: rgba(7, 187, 68, 0.19);
}

.insight_leave:nth-child(4){
  background-color: rgba(205, 35, 127, 0.19);
}

.insight_leave:nth-child(5){
  background-color: rgba(143, 111, 129, 0.19);
}

.insight_leave:nth-child(6){
  background-color: rgba(175, 179, 212, 0.19);
}

.insight_leave:nth-child(7){
  background-color: rgba(205, 35, 35, 0.19);
  ;
}
.insight_leave_box{
  width: 90%;
  margin: 0 auto;
}
.insight_leave_right{
  width: 90%;
  margin: 0 auto;
 
}
.insight_leave:not(.big) svg {
  font-size: 3rem;
  color: var(--clr-primary);
}
.insight_leave:is(.big) svg {
  font-size: 2rem !important;
  color: var(--clr-primary);
}
.insight_leave-title{
  width: 100%;
}
.insight_leave-title h6 {
  font-weight: 500;
  font-size: 1.2rem;
}
.insight_leave-content{
  font-size: 2rem;
}
.insight {
  padding: 1rem;
  border-radius: 8px;
  height: 100%;
}

.insight .nobox {
  border-radius: none;
  width: 25vw;
}

.insight.big {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
}

.insight:not(.big) svg {
  font-size: 3rem;
  color: var(--clr-primary);
}

.insight:is(.big) svg {
  font-size: 2rem !important;
  color: var(--clr-primary);
}

.insight-title h4 {
  font-weight: 500;
  font-size: 1.15rem;
}

.insight-content h2 {
  font-weight: 500;
  font-size: 1.8rem;
}

.no-content-btn {
  border: none;
  padding: 0.5rem;
  margin-inline: auto;
  background-color: transparent;
  display: block;
}

.onboarding-header {
  height: 20vh;
  min-height: 200px;
  padding: 1rem 3rem;
  background-color: var(--clr-primary);
  color: var(--clr-white);
}

.onboarding-header h2 {
  color: inherit;
}

.onboarding-header + .container-fluid {
  height: 80vh;
  overflow-y: auto;
}

.onboarding-form {
  width: 100%;
  margin-top: 20px;
}

.onboarding-form label {
  font-weight: 500;
  font-size: 1rem !important;
  color: var(--clr-grey);
  margin: 0;
}

.onboarding-form input,
.onboarding-form textarea,
.onboarding-form select {
  border: 1px solid var(--clr-off-white);
  padding: 0.3rem 1rem;
  border-radius: 6px !important;
  margin-top: 7px;
  outline: none;
  width: 100%;
}

.setup-main {
  min-height: 70vh;
  padding: 1rem;
}

.onboarding-setup {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.onboarding-setup li {
  padding: 0.5rem 1rem;
}

.onboarding-setup li a {
  color: var(--clr-black);
  font-size: 0.9rem;
  width: 100%;
  transition: 0.3s ease-in-out;
}

.onboarding-setup li a.active {
  font-size: 1.2rem !important;
  font-weight: 700;
  color: var(--clr-primary);
}

.file-uploaded-icon {
  font-size: 6rem;
  color: var(--clr-primary);
}

.activity-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.activity-item + .activity-item {
  margin-top: 20px;
}

.activity-item button {
  color: red;
  border: none;
  background-color: transparent;
}

.profile-badge {
  background-color: var(--clr-primary);
  padding: 0.1rem 0.5rem;
  color: var(--clr-white);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress {
  background-color: #d9d9d9;
  height: 10px !important;
  width: 80% !important;
}

.progressBar {
  background-color: var(--clr-primary);
}

.supervisors {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 250px;
  overflow-y: auto;
}

.supervisors::-webkit-scrollbar {
  display: auto !important;
  width: 3px;
}

.supervisors::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.supervisors::-webkit-scrollbar-thumb {
  background: #ababab;
}

.supervisors + button {
  /* display: flex; */
  float: right;
}

.supervisor {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  gap: 5px;
  align-items: center;
}

.supervisor .image {
  width: 50px;
  height: 50px;
}

.supervisor .image img {
  object-fit: contain;
  border-radius: 50%;
}

.supervisor .cta {
  cursor: pointer;
}

.supervisor .cta label {
  line-height: 1;
  margin-bottom: 0;
}

.supervisor .cta svg {
  font-size: 1.3rem;
}

.supr-see {
  cursor: pointer;
  transition: text-decoration 0.5s;
}

.supr-see:hover {
  text-decoration: underline;
}

.progress-status {
  margin-top: 50px !important;
}

.templates {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 80%;
  margin-inline: auto;
}

.template {
  cursor: pointer;
  background-color: rgba(217, 217, 217, 0.26);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  justify-content: space-between;
  gap: 20px;
}

.template h4 {
  font-size: 1.2rem;
}

.template p {
  line-height: 1;
  font-size: 0.8rem !important;
}

.template .activity_count {
  width: 25px;
  display: inline-block;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 600;
  color: var(--clr-white);
  background-color: var(--clr-primary);
}

.insightBar {
  min-width: calc(100vw - 300px);
  overflow-x: auto;
  margin-bottom: 50px;
}

.insightBar::-webkit-scrollbar {
  display: none;
}

.insightBox {
  min-width: 300px;
  display: flex;
  padding: 1rem;
  color: var(--clr-black);
}

.insightBox svg {
  font-size: 3rem;
}

.insightBox h4,
.insightBox h2 {
  font-size: 1.2rem;
  color: var(--clr-black);
}

.insightBox h2 {
  font-size: 2.5rem;
}
/* } */


@media (max-width: 1200px) {
  .insight-content h2 {
    font-weight: 500;
    font-size: 1.4rem;
  }
}


@media (max-width: 580px) {
  .insight-content h2 {
    font-weight: 500;
    font-size: 1.2rem;
  }
}