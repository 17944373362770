.main__wrapper {
  position: relative;
  left: 25rem;
  width: calc(100% - 25rem);
}
.performance__nav__wrapper {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 200;
}
.performance__nav {
  height: 4.5rem;
  background-color: var(--clr-white);
  padding: 0 0 0 20px;
}
.performance__nav__subnav {
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;
  background-color: #e6e5e4;
  padding-top: 1rem;
  margin-bottom: 1rem;
  /* box-shadow: 1px 8px 5px -1px rgba(0,0,0,0.16); */
}

.performance__nav__subnav .dropdown_links {
  display: flex;
  gap: 1rem;
}

.performance__nav__subnav .dropdown_links a {
  color: var(--clr-black);
  padding: 10px;
}

.performance__nav__subnav .dropdown_links .active {
  color: var(--clr-primary);
  /* border-bottom: 3px solid var(--clr-primary); */
  padding: 10px;
  font-weight: 500;
}

.performance__nav h3 {
  width: 70%;
}

.performance__navlinks {
  display: flex;
  height: 100%;
}

.performance__navlinks a,
.performance__navlinks .dropdown {
  font-size: 1.1rem !important;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  color: var(--clr-black);
  transition: 0.1s ease-in-out;
}

.performance__navlinks .dropdown_links {
  display: flex;
  /* position: absolute; */
  /* top: 3.5rem; */
  /* left: 0; */
  /* width: 100vw; */
}

.performance__navlinks .dropdown_links a {
  display: flex;
  align-items: center;
  text-decoration: underline;
}

.performance__navlinks a svg,
.performance__navlinks .dropdown svg {
  font-size: 1.5rem;
}

.performance__navlinks a:hover,
.performance__navlinks .dropdown:hover {
  color: var(--clr-white);
  background-color: var(--clr-primary);
  font-weight: bold;
}

.performance__navlinks a.active,
.performance__navlinks .dropdown.active {
  color: var(--clr-white);
  background-color: var(--clr-primary);
  font-weight: bold;
}

.dropdown_links > li > a {
  border-left: none !important;
}

.performance__content {
  grid-column: span 11;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
}
.pdr-12 {
  padding-left: 12px;
}
.performance-header {
  height: 100%;
  min-width: 25rem;
  width: 25rem;
  position: fixed;
}

.img__fixed {
  width: 265px;
  height: 185px;
}

.bg-white__reset {
  background-color: var(--clr-white);
  min-height: 80vh;
}

.performance-header h2 {
  color: inherit;
}

.performance-header + .container-fluid {
  overflow-y: auto;
}

.stat-card,
.graph-card {
  box-shadow: 0px 2px 16px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
}

.stat-card select {
  border: none;
  outline: none;
  padding-block: 0.5rem;
  width: 100%;
}

.stat-card h2 {
  font-size: 3rem;
}

.goals__nav {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
  padding-block: 0.9rem 0.5rem;
}

.goal__nav__links li a {
  padding: 0.5rem 0;
  color: var(--clr-black);
}

.goal__nav__links li a.active {
  border-bottom: 3px solid var(--clr-primary);
  font-weight: 500;
}

.circle {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--clr-white);
  border-radius: 50%;
  background-color: orange;
}

.performance-form {
  width: 100%;
  margin-top: 20px;
}

.performance-form label {
  font-weight: 500;
  font-size: 1rem !important;
  color: var(--clr-grey);
  margin: 0;
}

.performance-form input:not([data-my]),
.performance-form textarea,
.performance-form select {
  border: 1px solid var(--clr-off-white);
  padding: 0.4rem 1rem;
  border-radius: 6px !important;
  margin-top: 7px;
  outline: none;
  width: 100%;
  height: 38px;
}

.kpi-goal-card {
  border: 1px solid var(--clr-primary-fd) !important;
  background-color: rgba(247, 247, 250, 1) !important;
}

.kpi-goal-card.selected {
  border: 1px solid var(--clr-primary-fd);
  background-color: rgba(247, 247, 250, 1);
}
.performance__limit_text {
  display: block;
  width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.performance__year_btn {
  border: 1px solid var(--clr-off-white);
  padding: 0.3rem 1rem;
  border-radius: 6px !important;
  margin-top: 7px;
  outline: none;
  width: 100%;
  cursor: pointer;
}

.performance__reset__link {
  color: inherit;
}

.performance__reset__link:hover {
  color: inherit;
}
.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}
.performance__bagde {
  padding: 10px 15px;
  background-color: var(--clr-primary);
  color: #fff;
  font-weight: 500;
}
.performance__bagde2 {
  padding: 12px;
  background-color: var(--clr-primary);
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 20px !important;
}
.performance__bagde3 {
  padding: 5px 10px;
  background-color: #d9d9d9;
  /* color: #fff; */
  /* font-weight: 500; */
  font-size: 12px !important;
  cursor: pointer;
  border-radius: 0px !important;
}
.performance__card {
  min-height: 200px;
  border-radius: 0px;
  width: 90%;
  background: #f4f4f4;
  margin-bottom: 15px;
  position: relative;
  /* padding: 0 20px; */
  color: var(--clr-primary);
}
.performance__card + div {
  width: 90%;
}
.performance__wave {
  fill: var(--clr-primary);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 0;
}

.performance__image {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: block;
  border: 1px solid var(--clr-primary);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

/* .performance__cl {
    padding: 25px 0px 25px 0px;
  } */

.performance__cont {
  padding-inline: 25px;
}
.performance__im {
  width: 100px;
  height: 100px;
  /* display: block; */
  object-fit: contain;
}

.performance__step .ant-steps-item-content {
  margin-top: 15px;
}
.performance__w-35 {
  width: 35% !important;
}

.peformance__toggle__close {
  display: none;
}

.messaging__card {
  /* border: 1px solid var(--clr-primary); */
  /* height: 350px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.collasape__card {
  /* border: 1px solid var(--clr-primary); */
  /* height: 350px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}
.collasape__card__light {
  /* border: 1px solid var(--clr-primary); */
  /* height: 350px; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important; */
  padding-inline: 25px;
  background: var(--clr-white);
}
.card__heading {
  background-color: var(--clr-primary);
  color: var(--clr-white);
  padding: 0.8rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--clr-primary);
}
.collasape__heading {
  background-color: var(--clr-primary);
  color: var(--clr-white);
  padding: 0.8rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--clr-primary);
}
.collasape__heading__light {
  background-color: var(--clr-white);
  /* color: var(--clr-white); */
  /* padding-block: 0.8rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid var(--clr-primary); */
}

.card__heading h5 {
  /* font-size: 1.2rem; */
  margin: 0px;
}
.collasape__heading__light h5 {
  /* font-size: 1.2rem; */
  margin: 0px;
}

.card__body {
  transition: height 1s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.collasape__body {
  transition: height 1s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--clr-white);
  height: auto;
  padding-block: 20px;
}
.collasape__body__light {
  transition: height 1s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--clr-white);
  height: auto;
  padding-block: 20px;
}

.card__body.collapsed {
  height: 0px;
  overflow: hidden;
}
.collasape__body.collapsed {
  height: 0px;
  overflow: hidden;
  padding: 0px;
}
.collasape__body__light.collapsed {
  height: 0px;
  overflow: hidden;
  padding: 0px;
}

.message__area {
  height: 300px;
  overflow-y: auto;
}

.message__chat {
  display: flex;
  gap: 15px;
  /* justify-content: end; */
  width: 430px;
  align-items: center;
  margin-block: 35px;
  padding-inline: 1rem;
}
.message__chat.Self {
  margin-left: auto;
  flex-direction: row-reverse;
}
.chat__text {
  flex: 1;
}

.info__text {
  display: flex;
  font-size: 0.9rem;
  justify-content: space-between;
  color: #b5b5b5;
}
.no__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.no__content img {
  width: 130px;
  /* margin-bottom: 20px; */
}
.no__content p {
  width: 230px;
  margin-inline: auto;
  font-size: 15px !important;
  margin-block: 30px !important;
}
.input__section {
  display: flex;
  padding-inline: 1rem;
}

.input__section .first {
  flex-grow: 1;
  border: 2px solid #bebebe;
  height: 50px;
}
.input__section .first {
  flex-grow: 1;
  border: 1px solid #bebebe;
  height: 50px;
}

.first input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  padding-inline: 10px;
}
.input__section .second {
  border: 1px solid #bebebe;
  background-color: #bebebe;
  height: 50px;
  padding-inline: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  margin-bottom: 7px;
  cursor: pointer;
}

.input__section .second:active {
  background-color: var(--clr-primary);
  color: var(--clr-white);
  border: 1px solid var(--clr-primary);
}

.mobile-icon-toggler:hover .sidebar__menu {
  transform: scale(1.1);
}

.sidebar__toggler:hover .sidebar__menu {
  transform: scale(1.1);
}
.app__section {
  border-top: 3px solid var(--clr-primary);
  background: #fff;
  padding-inline: 25px;
  /* min-height: 75vh; */
}
.app__section_no_border {
  /* border-top: 3px solid var(--clr-primary); */
  background: #fff;
  padding-inline: 25px;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important; */
}
.app__section_no_padding {
  border-top: 3px solid var(--clr-primary);
  background: #fff;
  /* padding-inline: 25px; */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.row__no_margin {
  margin-inline: 0px !important;
}
/* .app__body {
  background: rgba(216, 216, 216, 0.49);
} */

.expand__reset {
  height: 100%;
  padding: 2rem;
  background: var(--clr-white);
}

.trans {
  height: auto;
  transition: height 2s ease !important;
}

.slide__down .slider__others {
  display: none !important;
  height: 0;
  transition: height 2s ease !important;
}
.see__more {
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.circle__bagde {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 46px;
  background: #d9d9d9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 20px;
}
.circle__bagde_pul {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  background: #d9d9d9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  /* position: fixed !important;
  z-index: 999999999; */
}
.fixed {
  position: fixed;
  right: 50px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 99999999999999999999999999999999999999999999;
}
.pointer {
  cursor: pointer;
  transition: 0.3s;
}
.pointer:hover {
  transform: scale(0.99);
}
.no-cursor {
  cursor: default !important;
  color: inherit !important;
  font-weight: normal !important;
}
.ruler {
  color: var(--clr-primary);
}

.short__bagdes {
  padding: 15px;
  border-top: 2px solid var(--clr-primary);
  white-space: nowrap;
  background-color: #ccc !important;
  font-weight: bold;
}
.filter_search {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bs-light);
  padding: 5px;
}
.filter_search input {
  outline: none;
  border: none;
  background: none;
  padding-left: 5px;
}

.percent__unit {
  font-size: 32px;
  font-weight: bold;
}

.taxable__bagde {
  padding: 5px 10px;
  background: #226c16;
  color: #fff;
}
.notaxable__bagde {
  padding: 5px 10px;
  background: #6c16637d;

  color: #fff;
}
.pension__bagde {
  padding: 5px 10px;
  background: #124047;

  color: #fff;
}
[data-my="red"] {
  border-radius: 0px 5px 5px 0px !important;
}
.birthday__title {
  background-color: var(--clr-primary);
  color: var(--clr-white);
  padding: 20px;
}
.header__title {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 334px;
  margin-inline: auto;
}

.header__title_img {
  width: 136px;
  height: 136px;
  border-radius: 100%;
}

.header_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
}
.header_body h6 {
  font-size: 17px;
  margin: 0;
}
.header_body h3 {
  font-size: 29px;
  margin: 0;
}
.header_body p {
  color: #858585;
  margin: 0;

  font-size: 17px;
}

.header__body {
  background: rgba(217, 217, 217, 0.38);

  padding: 20px;
  margin-top: 20px;
}

.header__body p {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 17px !important;
}

.header__body_family {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}

.family__member {
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 149px;
  align-items: center;
}

.family__member p:nth-child(2) {
  font-size: 17px !important;
  font-style: normal;
  font-weight: 600;
}
.family__member p:nth-child(3) {
  font-size: 11px !important;
  font-style: normal;
  font-weight: 600;
}
.family__member p:nth-child(4) {
  font-size: 17px !important;
  font-style: normal;
  font-weight: 600;
}
.family__member img {
  width: 82px;
  height: 82px;
  border-radius: 100%;
}

#team.table > :not(caption) > * > * {
  padding: 1rem 0.5rem !important;
}
.team__modal__body {
  padding: 20px;
}
.team__body_title {
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
}
.team__img {
  width: 34px;
  height: 34px;
  border-radius: 100%;
}
.team__name,
.team__fullname,
.team__place,
.team__grade,
.team__step {
  font-size: 13px !important;
  font-weight: 600;
}

.team__name {
  display: flex;
  flex-direction: column;
  /* gap: 2px; */
}

.team__name span {
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
.team__fullname {
  color: #bad;
  margin-top: 6px !important;
}
.team__place {
  color: #8d690c;
  margin-top: 6px !important;
}
.team__grade {
  color: #1e9c2b;
  margin-top: 6px !important;
}
.team__step {
  color: #1e509c;
  margin-top: 6px !important;
}

.leave__modal_body {
  display: flex;
  gap: 18px;
  padding: 18px;
  align-items: center;
}
.leave__right {
  flex-grow: 1;
}

.leave__right span {
  font-size: 16px;

  font-weight: 600;
}
.progress_number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
}

.progress_details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#ppp .ant-progress-bg {
  height: 13px !important;
}
span.dgg {
  margin-bottom: 12px;
}
.title__area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.title__area_right {
  display: flex;
  gap: 17px;
  flex-wrap: wrap;
}

.title__area_right .svg_icon {
  /* color: var(--clr-primary); */
  font-size: 20px;
}
.title__area_right li {
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 5px;
}
.input__box {
  border: 1px solid rgba(217, 217, 217, 0.61);
}
.title__area_right li input {
  outline: none;
  border-radius: 10px !important;
  background-color: #fff;
  padding-inline: 5px;
  border: 3px solid #1c315e0d;
  height: 40px;
}

.emp__area {
  padding-block: 46px;
  padding-inline: 26px;
  background-color: transparent;
  color: var(--clr-primary) !important;
}
.emp__card {
  display: flex;
  flex-direction: column;
  gap: 11px;
  border: none !important;
}
.emp__img img,
.emp__img_fullemploy img {
  width: 100%;
  height: 123px;
  object-fit: cover;
  /* border-radius: 10px; */
  margin: 0 auto;
}

.emp__info__area {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
span.emp__name {
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
}
span.emp__job_role {
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}
span.emp__category {
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
}

.card__grid {
  display: grid;
  gap: 30px;
  /* grid-gap: 29px 40px; */
  /* grid-column-gap: 40px !important;
  grid-row-gap: 29px; */
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
.pointer .okk {
  text-decoration: none !important;
  color: black;
}
.pointer a.active {
  color: var(--clr-primary) !important;
}

.padding_3rem {
  padding: 3rem !important;
}
#label__leave {
  font-size: 12px !important;
  font-weight: bold !important;
}

.rpt_img {
  width: 100%;
  object-fit: contain;
}

.id__card {
  width: 302.36px;
  height: 188.97px;
  max-width: 302.36px;
  max-height: 188.97px;
  font-weight: 550;
  font-size: 9.5px;
  border-radius: 6px;
  /* color: #fff; */
  background: linear-gradient(var(--clr-white), var(--clr-primary));
  padding: 5px;
}

.id__card .head__section {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: thin solid #fff;
  letter-spacing: 1px;
  font-weight: 700;
}
.com__name {
  font-size: 13px;
}

.id__card .body__section {
  display: flex;
  gap: 10px;
  margin-top: 5px;
  /* align-items: center; */
}

.img__card {
  width: 68px !important;
  height: 68px !important;
  object-fit: contain;
  margin-top: 15px;
}

.info__section__id-card small {
  display: block;
  font-size: 12px;
  /* margin-bottom: 5px; */
  border-bottom: thin solid #fff;
  padding: 5px;
}
.info__section__id-card small img {
  height: 33px;
  border-bottom: thin solid transparent;
  object-fit: contain;
}

.id__card-back {
  width: 302.36px;
  height: 180.97px;
  max-width: 302.36px;
  max-height: 180.97px;
  font-weight: 550;
  font-size: 11px;
  border-radius: 6px;
  /* color: #fff; */
  background: linear-gradient(var(--clr-white), var(--clr-primary));
  padding: 10px;
}

.first__section {
  width: 100%;
  text-align: center !important;
}

.com__name {
  display: block;
  font-weight: 700;
}

.back__img {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.back__img img {
  margin-inline: auto !important;
}

.back__img p {
  font-size: 12px !important;
}

.id__card_section {
  width: 70% !important;
  margin-inline: auto;
}

.id__card_profile {
  margin-bottom: 36px;
}
.img__profile {
  width: 117px;
  height: 117px;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px var(--clr-primary);
}

.id__card__title {
  font-size: 12px;
  display: block;
  font-weight: 700;
}
.id__card__info {
  font-size: 14px;
  display: block;
  font-weight: 500;
}
.id__card__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.small_bold {
  font-size: 12px;
  font-weight: bold;
}

.mobile_header {
  display: none;
}

  .spacing {
    margin-right: 0px;
  }


@media (max-width: 1200px) {
  .main__wrapper {
    position: relative;
    left: 0;
    width: 100%;
  }
  .performance-header {
    padding: 0px;
    height: auto;
    min-height: auto;
    padding: 0px 7px;
  }

  .performance__nav__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    right: 0;
    left: 0;
    z-index: 200;
  }
  
   .performance__navlinks .dropdown_links {
    display: flex;
    flex-direction: column;
  } 
  

  .performance__nav {
    position: fixed;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-top: 6rem;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 20rem;
    z-index: 9999;
    background: var(--clr-primary);
    transition: var(--transition-default);
    transform: translateX(-100%);
  }

  .sidebarToggle .performance__nav {
    position: fixed;
    width: 20rem;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    transform: translateX(0);
    transition: var(--transition-default);
  }

  .performance__navlinks {
    position: relative;
    margin-top: 5rem;
    width: 97%;
    /* padding: 0 0.5rem; */
    display: flex;
    flex-direction: column;
    height: 100%;
  }

    
  .performance__navlinks a,
  .performance__navlinks .dropdown {
    font-size: 1.1rem !important;
    display: flex;
    margin-top: 0.5rem;
    align-items: center;
    height: 100%;
    padding: 1rem 15px;
    color: var(--clr-white);
    transition: 0.1s ease-in-out;
  }

  /* .performance__navlinks a:hover {
    border: 1px solid var(--clr-white);
    border-radius: 0.5rem; 
  } */

  .performance_sider {
    display: flex;
    align-items: center; 
    justify-content: space-between; 
    width: 100%; 
    gap: 1rem; 
    /* padding: 0.5rem; */
    width: 260px;
  }

  .performance__nav__subnav {
    /* display: flex;
    height: auto;
    position:absolute; */
    display: none;
    /* top: 40rem;
    left: 1rem;
    z-index: 999999;
    padding: 1rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color:  transparent; */
  }

.performance__navlinks a.active,
.performance__navlinks .dropdown.active {
  color: var(--clr-white); 
  /* background-color: var(--clr-white); */
  /* opacity: 0.4; */
  border-radius: 0.5rem; 
  border: 2px solid var(--clr-white);
  font-weight: bold; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
}


  .performance__nav__subnav .dropdown_links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .performance__w-35 {
    width: 46% !important;
    margin-inline: auto;
    margin-bottom: 20px;
  }
  
   .pdr-12 {
      justify-content: space-between;
   } 
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-spin .ant-spin-dot-item {
  background-color: var(--clr-primary) !important;
  color: var(--clr-primary) !important;
}