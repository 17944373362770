.radio_qr{
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: end;
    /* margin-right: 50px; */
    margin-bottom: 10px;
}
.radio_qr label{
    display: flex;
    align-items: center;
    margin-right: 107px;
}
.radio_qr span{
    display: inline-block;
    width: 20px;
    margin-left: 5px;
}
.qr_label span{
    /* border: 1px solid red; */
    display: inline-flex;
    justify-content: center;
    font-size: 18px;
}