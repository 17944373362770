.salary_inner{
    width: 95% !important;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 35px;
}
.salary_inner > div{
    width: 250px;
    height: 135px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--clr-primary);
    border-radius: 5px;
    position: relative;
}

.salary_inner p{
    margin-bottom: 5px !important;
    color: inherit !important;
}
.salary_inner h4{
    color: inherit !important;
}
.eye_icon_dashboard{
    position: absolute;
    top: 0;
    left: 10px;
    cursor: pointer;
    font-size: 18px;
    color: black !important;
}
.eye_icon_dashboard:hover{
    transform: scale(1.1);
}
.gross_salary, .net_salary, .tax_salary, .deductions_salary{
    width: 100%;
    text-align: center;
}