.container {
  display: flex;
  height: 100vh;
  width: 100%;
}  

.layoutContent {
  position: fixed;
  margin-left: 21rem;
  overflow-y: scroll;
  width: 100%;
  height: calc(130vh - 50px);
  padding-left: 2rem;
  max-width: 1600px;
  transition: var(--transition-default);
}

.layoutContent::-webkit-scrollbar {
  display: none;
}

.layoutContentToggled {
  position: fixed;
  left: 0;
  overflow-y: scroll;
  margin-left: 0;
  max-width: 100%;
  width: 100%;
  height: calc(130vh - 50px);
  transition: var(--transition-default);
  padding-left: 4rem;
}

.greeting {
  font-weight: 500;
  font-size: 2rem;
  background-position: left 70%;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 10vh;
  /* position: relative;
  left: 1rem; */
  display: flex;
  padding: 2rem;
  color: var(--clr-primary);
  flex-direction: column;
  /* align-items: center; */
  /* gap: 10px; */
}

.layoutContentToggled.greeting {
  position: relative;
  left: 1rem;
}

.header {
  height: 100px;
  display: flex;
  align-items: center;
  background-color: var(--clr-primary);
  /* position: relative; */
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 200;
}

.loader {
  position: absolute;
  right: 0;
  top: 0;
}

.main {
  height: calc(100vh - 170px);
  display: flex;
}

.layout {
  display: grid;
  grid-template-columns: 300px repeat(11, 1fr);
  gap: 50px;
  height: calc(100vh - 170px);
  width: 100%;
  
}

.content {
  grid-column: 2/-1;
  overflow-y: unset;
  margin-top: 50px;
  height: calc(100vh - 220px);
}

.navbar {
  height: calc(130vh - 180px);
  overflow-y: auto;
  /* position: relative; */
  margin-top: 50px;
  position: fixed;
  padding-bottom: 90px !important;
}

.navbar::-webkit-scrollbar {
  display: none;
}


.profileImage {
  height: 300px;
  width: 300px;
  max-width: 300px;
  max-height: 300px;
  overflow: hidden;
  margin-bottom: 20px;
  object-fit: cover;
}

.profileImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.workYears {
  background-color: var(--clr-primary);
  padding: 1rem;
  margin-block: 20px;
  text-align: center;
}

.workYears p {
  margin: 0;
  font-size: 1rem;
  color: var(--clr-white);
  font-weight: 500;
}

.workYears p span {
  font-weight: 400;
}

.breakdowns,
.breakdown {
  display: flex;
}

.breakdowns {
  justify-content: space-between;
  padding: 1rem;
  background-color: var(--clr-primary);
  color: var(--clr-white);
}

.breakdown {
  flex-direction: column;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
}
.breakdown:hover {
  transform: scale(1.05);
}

.breakdown p {
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
}

.breakdown p:nth-child(2) {
  font-size: 0.8rem;
}

.birthdays {
  overflow-y: auto;
  scrollbar-width: 2px;
  padding-right: 1rem;
  height: 214px;
}

.birthdays h3 {
  display: flex;
  gap: 10px;
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.bCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.bCard img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.bCard h3 {
  font-size: 1rem;
  margin-bottom: 0;
  text-overflow: ellipsis;
}

.bCard p {
  font-size: 0.8rem !important;
  margin-bottom: 0;
}

.bCountdown {
  border-radius: 8px;
  padding: 0.5rem;
  background-color: #ccc;
}

.bCountdown h4 {
  color: var(--clr-white) !important;
  margin-bottom: 0;
  font-size: 0.8rem;
}

.bCard + .bCard {
  margin-top: 20px;
}

.bCard p {
  font-size: 1rem;
  margin: 0;
}

.bCard p span {
  font-weight: 500;
}

.spinner {
  position: absolute;
  top: 10px;
  right: 10px;
}

.displayUser {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
}

.displayUser h1 {
  color: var(--clr-white);
  font-size: 1.5rem;
  margin: 0 !important;
}

.userCompany {
  border: 1px solid var(--clr-white);
  border-radius: 50%;
  aspect-ratio: 1;
  background-color: var(--clr-white);
  padding: 0.2rem;
  width: 50px;
  height: 50px !important;
  object-fit: contain;
}

.userCompany img {
  border-radius: 50%;
  width: 100%;
  aspect-ratio: 1;
  width: 50px;
  height: 50px;
}

.drawerTitle {
  text-align: center;
  margin-block: 1rem;
}

.drawerTitle svg {
  font-size: 2.5rem;
  color: var(--clr-primary);
  margin-bottom: 10px;
}

.drawerTitle p {
  position: relative;
}

.spin {
  position: absolute;
  top: 3px;
  margin-left: 9em;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-60px);
    z-index: -10;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  100% {
    opacity: 0;
    transform: translateX(-60px);
  }

  0% {
    transform: translateX(0);
    opacity: 1;
  }
}

.logout {
  color: red;
}

.base {
  margin-top: 15vh;
  min-height: 40vh;
}

.welcome {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0;
}

.welcome span {
  /* background-color: var(--clr-primary); */
  color: var(--clr-primary);
  /* padding: 0.5rem 1rem; */
}

.modalTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.finishSetup {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.finishSetup div {
  text-align: center;
}

.finishSetup h3 {
  margin-top: 10px;
  font-weight: 200;
  font-size: 23px;
}

@media (max-width: 480px) {
  .main {
    flex-direction: column;
    padding: 1rem;
  }

  .userCompany {
    display: none;
  }

  .userImage {
    top: -50px;
    right: 10px;
    width: 80px;
  }

  .user-company-name,
  .company-address,
  .company-country {
    font-size: 1.5rem !important;
  }

  .welcome {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    font-size: 1rem;
  }

  .modalTop {
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 1rem;
    gap: 1rem;
  }
}

.holidays {
  height: 300px;
  overflow-y: auto;
  scrollbar-width: 2px;
  padding-right: 1rem;
}

.twack {
  position: fixed;
  right: 20px;
  bottom: 50px;
  cursor: pointer;
  z-index: 900;
  height: 20px;
}
.twack_dasboard {
  position: fixed;
  right: 160px;
  top: 14px;
  cursor: pointer;
  z-index: 900;
}

.twack svg {
  font-size: 3rem;
  color: var(--clr-primary);
  animation: pulse 1s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.9);
  }
}

.profile_completeness {
  background-color: #fff;
  padding: 1rem;
  margin-block: 20px;
  text-align: center;
  color: var(--clr-primary) !important;
  width: 300px;
}



.notificationCard.removed {
  transform: translateX(100%);
  opacity: 0;
  pointer-events: none;
}

.notificationContent {
  max-width: 75%;
}

.notificationMessage {
  margin: 0.5rem 0;
  color: #555;
}

.markAsReadBtn {
  position: absolute;
  right: 10px;
  bottom: 10px;
}


@media (max-width: 1500px) {
  .layoutContent {
    max-width: 1450px;
  }

  .layoutContentToggled {
    max-width: 1600px;
    width: 100%;
  }
}

@media (max-width: 1380px) {
  .layoutContent {
    max-width: 1250px;
  }

  .layoutContentToggled {
    max-width: 1600px;
    width: 100%;
  }
}


@media (max-width: 1200px) {
  .layoutContent {
    position: fixed;
    margin-left: 0rem;
    overflow-y: scroll;
    width: 100%;
    height: calc(135vh - 180px);
    padding-left: 2rem;
    max-width: 1600px;
    transition: var(--transition-default);
  }

  .navbar {
    position: fixed;
    height: calc(135vh - 180px);
    margin-top: 50px;
    padding-bottom: 10px !important;
  }
   
}

@media (max-width: 768px) {
   .layoutContent {
    padding-left: 0;
    max-width: 1600px;
    overflow-x: hidden;
  }

  .layout {
    grid-template-columns: 1fr; 
  }
  .content {
    grid-column: span 1; 
  }

   .navbar {
    position: relative;
    margin-top: 20px;
    padding-bottom: 0;
    height: 65vh;
  }

  .greeting {
    padding-bottom: 5rem;
    text-align: center;
  }

  .sider {
    position: relative;
    top: 10rem;
  }

  .sidebarContent {
    padding: 0 1.2rem;
  }

   .profileImage {
    margin: auto;
    padding-bottom: 1rem;
    object-fit: cover;
  }

  
.profile_completeness {
  background-color: #fff;
  padding: 1rem;
  margin-block: 20px;
  margin: auto;
  text-align: center;
  color: var(--clr-primary) !important;
  width: 300px;
}
}


@media (max-width: 767px) {

  .navbar {
    position: relative;
    margin-top: 40px;
    height: 80vh;
  }
  .sider {
    position: relative;
    top: 0;
  }

  .greeting {
    padding-bottom: 0;
    position: relative;
    bottom: 60rem;
    width: 100%;
    text-align: center;
    /* left: 0.5rem; */
  }
}

@media (width: 540px) {
  .greeting {
    bottom: 50rem;
  }
}

@media (max-width: 380px) {
  .greeting {
    bottom: 50rem;
    margin-top: 1rem;
  }
}

@media (max-width: 350px) {
  .greeting {
    bottom: 55rem;
  }
}


@media (max-width: 340px) {
  .greeting {
    bottom: 45rem;
  }
}