.setting {
  padding: 1rem;
  background-color: #eeeeee;
}

.setting .svg_icons {
  font-size: 2rem;
}

/* .setting h3,
.setting h4 {
  margin-bottom: 0;
} */

.setting h3 {
  font-size: 1.8rem;
}

.setting h4 {
  font-size: 1.3rem;
}

.setting small {
  color: var(--clr-red);
  width: 50% !important;
}

.category {
  margin-block: 30px;
}

.category input {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: none;
  padding-inline: 0.5rem;
}

.option-btn {
  background-color: transparent !important;
  font-size: 0.8rem !important;
}

.category button {
  border: none;
  background-color: #d9d9d9;
  padding: 0.3rem 1rem;
  font-size: 0.8rem;
  width: 100%;
}
.category button:hover {
  transform: scale(0.98);
}
.img_res {
  width: 150px;
  height: 150px;
  object-fit: contain;
}

.category .img_parent {
  position: relative;
  width: 150px;
  height: 150px;
}

.category .overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.hover_effect {
  font-size: 2rem;
}

.hover_effect:hover {
  transform: scale(0.9);
}
.modalImage {
  height: 60vh;
  object-fit: contain;
  border-radius: 8px;
  margin-inline: auto;
}

.style_btn {
  background-color: var(--clr-primary);
  color: var(--clr-white);
  border: none !important;
}

.style_btn:hover {
  background-color: var(--clr-primary);
  color: var(--clr-white) !important;
  transform: scale(0.98);
}
.res_btn {
  border-radius: 0;
}
.selected_btn {
  border: thin solid greenyellow !important;
  background: green !important;
  color: #fff;
}
.ant-btn.res_btn:hover {
  color: black;
  /* transform: scale(0.98); */
}

.adjust_style {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.adjust_style_password {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
}
.res_form {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  margin-block: 5px;
}
.fonnt {
  font-size: 0.8rem !important;
}
