.subscription {
  min-height: calc(85vh - 50px);
  margin-top: 5vh;
  overflow-y: auto;
}

.modules,
.detachedModules {
  margin-bottom: 50px;
}

.modules h2,
.detachedModules h2 {
  margin-bottom: 20px;
}

.detachedCards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

@media (max-width: 1024px) {
  .subscription {
     padding: 0 1.5rem;
  }

  .detachedCards {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
}

@media (max-width: 480px) {
  .detachedCards {
    /* grid-template-columns: repeat(2, 1fr); */
    /* flex-direction: column; */
    gap: 20px !important;
    width: 100% !important;
  }

  .modules {
    margin-top: 3vh;
  }
}
