@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat:wght@400;500;600;700;900&family=Proza+Libre:wght@400;500;600;700&display=swap");

:root {
  --ff-logo: "Lato", sans-serif;
  --ff-heading: "Proza Libre", sans-serif;
  --ff-body: "Montserrat", sans-serif;

  --clr-primary: rgb(0, 101, 171);
  --clr-primary-fd: rgba(0, 101, 171, 0.4);
  --clr-primary-hover: rgba(0, 101, 171, 0.8);
  --clr-white: rgb(255, 255, 255);
  --clr-black: rgb(0, 0, 0);
  --clr-grey: rgb(91, 104, 113);
  --clr-off-white: rgb(204, 204, 204);
  --clr-red: rgb(255, 0, 0);
  --clr-green: rgb(0, 128, 0);

  --transition-default: all 300ms ease-in-out;

  --box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
}

.blue {
  --clr-primary: #0065ab;
  --clr-primary-fd: #0064abb3;
  --clr-primary-hover: #0064abcc;
}
.green {
  --clr-primary: #008000;
  --clr-primary-fd: #008000b3;
  --clr-primary-hover: #008000cc;
}
.red {
  --clr-primary: #cd0404;
  --clr-primary-fd: #cd0404b3;
  --clr-primary-hover: #cd0404cc;
}
.purple {
  --clr-primary: #6f1ab6;
  --clr-primary-fd: #6f1ab6b3;
  --clr-primary-hover: #6f1ab6cc;
}
.orange {
  --clr-primary: #e14d2a;
  --clr-primary-fd: #e14d2ab3;
  --clr-primary-hover: #e14d2acc;
}
.navy {
  --clr-primary: #1c315e;
  --clr-primary-fd: #1c315eb3;
  --clr-primary-hover: #1c315ecc;
}
.black {
  --clr-primary: #000000;
  --clr-primary-fd: #000000b3;
  --clr-primary-hover: #000000cc;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar {
  width: 3px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background: #cdcdcd;
}

body {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  min-height: 100vh;
  font-family: var(--ff-body);
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--ff-heading);
  /* color: var(--clr-primary); */
}

p {
  font-size: 1rem !important;
  font-weight: 400;
}

ul {
  list-style-type: none;
  padding: 0 !important;
}

img {
  display: block !important;
  max-width: 100% !important;
}

a {
  text-decoration: none !important;
  font-size: 1rem !important;
  font-weight: 400;
}

button:disabled,
button:disabled:hover {
  background-color: #ccc !important;
  cursor: not-allowed;
}

label {
  font-size: 0.8rem !important;
}

input {
  border-radius: 8px !important;
  font: inherit !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

.bg-white {
  background-color: var(--clr-white) !important;
}

.h-100 {
  height: 100% !important;
}

.container-fluid-reset {
  /* width: 90vw !important; */
  max-width: 1600px;
  /* padding-top: 210px !important; */
}
.container-fluid {
  --bs-gutter-x: 1.7rem !important;
}

#root,
.auth {
  height: calc(100vh + 33vh);
}

.my-container {
  width: 100% !important;
  margin-inline: auto;
}

/* INPUT CHECKBOX */
input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: var(--clr-primary);
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type="checkbox"]::before {
  content: "";
  width: 15.5px;
  height: 16px;
  border-radius: 2px !important;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 2em 3em var(--clr-primary);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.b_containter_reset {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* :where(.css-dev-only-do-not-override-diro6f).ant-select-multiple
  .ant-select-selection-item {
  line-height: 0px !important;
} */

.ant-select-selection-overflow {
  padding-block: 0.2rem;
}

:where(.css-dev-only-do-not-override-diro6f).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: var(--clr-off-white);
}
:where(.css-dev-only-do-not-override-diro6f).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):active
  .ant-select-selector {
  border-color: var(--clr-off-white);
}

.ant-pagination-item-link {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-table-filter-dropdown {
  padding: 5px !important;
}
[data-my] {
  border: 1px solid var(--clr-off-white);
  padding: 0.4rem 1rem;
  /* border-radius: 6px !important; */
  margin-top: 7px;
  outline: none;
  width: 100%;
}

.batch-select .ant-select-selector {
  height: 40px !important;
}

.batch-month {
  height: 40px !important;
}

.expense {
  border: 1px solid black !important;
  color: black !important;
}
.expense .ant-input-number-disabled {
  background-color: #f5f5f5; /* Light gray background */
  color: black !important; /* Lighter text color */
  cursor: not-allowed; /* Not-allowed cursor */
  opacity: 1; /* Ensure it's visible */
}

.expense .ant-input-number-disabled input {
  color: black !important; /* Ensure the text inside is styled */
}

.expense1 .ant-picker-disabled {
  background-color: #f5f5f5; /* Light gray background */
  color: black !important; /* Lighter text color */
  cursor: not-allowed; /* Not-allowed cursor */
  border: 1px solid black; /* Lighter border */
}

.expense1 .ant-picker-disabled input {
  color: black !important; /* Disabled input text color */
}

.expense1 .ant-select-disabled {
  background-color: #f5f5f5; /* Disabled background */
  color: black; /* Disabled text color */
  border: 1px solid #d9d9d9; /* Lighter border */
  cursor: not-allowed; /* Not-allowed cursor */
}

.expense1 .ant-select-disabled .ant-select-selection-item {
  color: black; /* Text color inside the select */
}

/* this is a temporary fix */
.ant-picker-disabled {
  background-color: #f5f5f5; /* Light gray background */
  color: black !important; /* Lighter text color */
  cursor: not-allowed; /* Not-allowed cursor */
  border: 1px solid black; /* Lighter border */
}
/* this is a temporary fix */
.ant-picker-disabled input {
  color: black !important; /* Disabled input text color */
}

.cursor-pointer {
  cursor: pointer !important;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-input-disabled, :where(.css-dev-only-do-not-override-2q8sxy).ant-input[disabled] {

  color: #000000 !important;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-checkbox-disabled+span {
   color: #000000 !important;
}
