.colorPreview {
width: 182px;
height: 120px;
display: flex;
align-items: center;
justify-content: center;
border: 1px solid var(--clr-off-white);
color: var(--clr-white);
/* margin-inline: auto; */
margin-bottom: 10px;
}

.colorPreview h3 {
  font-size: 1.5rem;
}

.btn {
  display: block;
  width: 182px;
  border: 1px solid var(--clr-off-white);
  padding: .5rem 1rem;
  border-radius: 8px;
  margin-bottom: 10px;
}

.colorOptions {
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  /* margin-inline: auto; */
}

.colorOptions label {
  position: relative;
   cursor: pointer;
   margin: 0;
}

.colorOptions label input[type="radio"] {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  appearance: none;
  cursor: pointer;
}

.colorOptions label div {
  min-width: 50px;
  aspect-ratio: 1;
  
}





.form {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}

.box {
  width: 100%;
}

.group label {
  display: flex;
  gap: 10px;
  position: relative;
  align-items: center;
}

.group input[type="radio"] {
  width: 25px;
  height: 25px;
}

.box {
  border: 1px solid var(--clr-off-white);
  height: 50px;
}

.preview {
  border: 1px solid var(--clr-off-white);
}

.header {
  min-height: 10vh;
  color: var(--clr-white);
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.header h2,
.body h4{
  margin-bottom: 0;
  font-size: 1.5rem;
}

.header small {
  margin-bottom: 0;
  font-size: 0.8rem;
}

.body {
  padding: 1rem;
}

.body p {
    font-size: 1rem;
}

.body button {
    color: var(--clr-white);
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border: 0;
    border-radius: 8px;
}

@media (max-width: 480px) {
  .form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}
