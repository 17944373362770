.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  background-color: rgba(255, 255, 255, 0.98);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 90%;
  height: fit-content;
  padding: 2rem;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  animation: slide-down 500ms ease-out forwards;
  background-color: var(--clr-white);
  overflow-y: auto;
  z-index: 9999;
}

@media (max-width: 480px) {
  .modal {
    height: 80vh;
  }
}

@media (min-width: 1440px) {
  .modal {
    max-width: 60vw;
    height: fit-content;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-4rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
