.card {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
}

.card h4 {
  margin: 0;
}

.card button {
  border: 0;
  border-radius: 6px;
  font-size: 1rem;
  padding: 0 1rem;
  background-color: var(--clr-primary);
  font-weight: 500;
  align-self: flex-end;
}

.card button a {
  color: var(--clr-white);
}

.content {
  border: 1px solid rgba(0, 0, 0, 0.08);
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: auto;
  gap: 20px;
}

.navigation {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
  gap: 5px;
}

.navigation li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--clr-black);
  position: relative;
}

.navigation li a::after,
.navigation li a.active::after {
  content: "";
  position: absolute;
  border: 0;
  border-right: 5px solid rgba(0, 0, 0, 0.2);
  top: -1px;
  bottom: -1px;
  right: -1px;
}

.navigation li a.active::after {
  border-right-color: var(--clr-primary);
}

.navigation li a.active {
  background-color: transparent;
  color: var(--clr-primary);
}

.details {
  grid-column: span 10;
  height: 100%;
  overflow-y: auto;
}

.form {
  width: 100%;
}

.form label {
  font-weight: 500;
  font-size: 1rem;
  color: var(--clr-grey);
  margin: 0;
}

.form input,
.form textarea,
.form select {
  border: 1px solid var(--clr-off-white);
  padding: 0.3rem .5rem;
  border-radius: 6px !important;
  margin-top: 7px;
  outline: none;
  width: 100%;
}

.form option {
  font-size: 1rem !important;
}

.cta {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.formBtn {
  margin-top: 20px;
  background-color: var(--clr-primary);
  color: var(--clr-white);
  border: none !important;
}

.formBtn:hover {
  background-color: var(--clr-primary);
  color: var(--clr-white) !important;
  transform: scale(0.98);
}



/* Responsive Design */
@media (max-width: 1024px) { /* For medium-sized screens */
  .content {
    grid-template-columns: repeat(6, 1fr); /* Reduce columns */
  }
  .navigation {
    grid-column: span 3; /* Expand the navigation to span more columns */
  }
}

@media (max-width: 768px) { 
  .content {
    grid-template-columns: repeat(4, 1fr); 
  }
  .navigation {
    grid-column: span 4;
    flex-direction: row; 
    overflow-x: auto; 
    height: auto;
  }
}

@media (max-width: 480px) {
  .content {
    grid-template-columns: repeat(2, 1fr); 
  }
  .navigation {
    grid-column: span 12; 
    flex-direction: row; 
    width: 100%;
  }
}