.header,
.groupName,
.right,
.input,
.info {
  display: flex;
}

.header {
  justify-content: space-between;
  margin-block: 20px;
  gap: 30px;
}

.groupName {
  flex-direction: column;
  width: 65%;
}

.groupName label {
  font-weight: 600;
  font-size: 1rem !important;
}

.groupName label span {
  color: var(--clr-red);
}

.groupName input {
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: 0;
  padding: .3rem 0.5rem;
  width: 50%;
}

.input,
.info {
  gap: 10px;
  align-items: center;
}

.input label {
  margin: 0;
}

.info svg {
  font-size: 1.5rem;
}

/* INPUT CHECKBOX */
.input input[type="checkbox"] {
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: var(--clr-primary);
  width: 25px;
  height: 25px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  /* transform: translateY(-0.065em); */
  display: grid;
  place-content: center;
  cursor: pointer;
}

.input input[type="checkbox"]::before {
  content: "";
  width: 19px;
  height: 19px;
  border-radius: 2px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 2em var(--clr-primary);
}

.input input[type="checkbox"]:checked::before {
  transform: scale(1);
}
