.heading {
  display: flex;
  justify-content: space-between;
  background-color: var(--clr-primary);
  color: var(--clr-primary);
  padding: 0.8rem 1rem;
}

.heading h4 {
  display: flex;
  gap: 10px;
  font-size: 1.2rem;
  margin: 0;
  color: var(--clr-white);
}

.body {
  padding: 1rem 0;
  background-color: var(--clr-white);
  min-height: 25vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  /* background-color: rgb(252, 247, 247); */
}
