.emp_desc{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.emp__job_role_desc{
    display: flex;
    flex-direction: column;
}
.emp__card_fullemploy{
    /* width: 150px !important; */
    display: flex;
    flex-direction: column;
    gap: 11px;
    border: none !important;
}

.card__grid_fullemploy{
    display: grid;
  gap: 35px;
  /* grid-gap: 29px 40px; */
  /* grid-column-gap: 40px !important;
  grid-row-gap: 29px; */
  grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
}


.chats{
    width: 30px;
    height: 30px;
}
.chats img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.allEmployee_main{
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding-bottom: 5rem;
    margin-top: 60px;
}
.allEmployee_head, .allEmployee_foot{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    position: sticky;
    top: 0;
}

.allEmployee_head > h3{
    font-size: 22px;
}

.card__grid_fullemploy{
   height: auto;
   margin-top: 60px;
}
.emp__card_fullemploy{
    height: 230px !important;
}
.allEmployee_head_filter {
    display: flex;
    gap: 20px;
    align-items: center;

}
.allEmployee_head_filter select{
    width: 207px;
    /* height: 45px; */
    /* background: rgba(22, 22, 108, 0.19); */
    /* padding: 0 14px; */
    /* border: none; */
    /* appearance: none; */
    outline: none;
    border-radius: 10px !important;
    background-color: #fff;
    padding-inline: 5px;
    border: 3px solid #1c315e0d;
    height: 40px;
}

.allEmployee_head_filter select:focus{
    outline: none;
}
.allEmployee_head_filter input[type="text"]{
    width: 173px;
    /* height: 45px; */
    /* padding: 14px 17px 14px 17px; */
    /* background: rgba(22, 22, 108, 0.19); */
    /* border: none;
    border-radius: 0 !important; */
    outline: none;
    border-radius: 10px !important;
    background-color: #fff;
    padding-inline: 5px;
    border: 3px solid #1c315e0d;
    height: 40px;
}
.allEmployee_head_filter input[type="text"]:focus{
    outline: none;
}