.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.header button {
  background-color: var(--clr-primary);
  color: var(--clr-white);
  border: none;
}

.header button a {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--clr-white);
}

.main p {
  font-size: 1rem;
  margin-bottom: 50px;
}

.card {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.card h4,
.card p {
  margin: 0;
}

.card p {
  font-size: 1rem;
}

.card p span {
  font-weight: 500;
}

.card button {
  border: 0;
  border-radius: 6px;
  font-size: 1rem;
  padding: 0 1rem;
  background-color: var(--clr-primary);

  font-weight: 500;
}

.card button a {
  color: var(--clr-white);
}

@media (max-width: 768px) {
  /* .card button {
    display: flex;
    justify-content: space-between;
  } */
  
}
