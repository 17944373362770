.permission {
  border-radius: 4px;
  border: 1px solid #0000002b;
  background-color: #d9d9d959;
}

.permission + .permission {
  margin-top: 20px;
}

.title,
.right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title h4 {
  font-weight: 500;
  font-size: 1.2rem;
  margin: 0;
  padding: 1rem;
}

.right {
  padding: 1rem;
  gap: 10px;
}

.right button {
  border: 0;
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.5) !important;
  cursor: pointer;
}

.right button svg {
  font-size: 1.5rem;
}

.options {
  font-size: 0.8rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
  gap: 20px;
  padding: 2rem;
  background-color: var(--clr-white);
}

.option {
  border: 1px solid #0000002b;
  padding: 0.5rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  cursor: pointer;
  width: 20rem;
  max-width: 20rem;
  height: 7rem;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.top img {
  width: 50px;
  aspect-ratio: 1;
}

.top h3 {
  font-size: 1rem;
  /* width: 85%; */
  white-space: nowrap;
  display: inline-block;
  text-wrap: wrap;
}

.bottom {
  display: flex;
  justify-content: space-between;
  /* text-align: end; */
}

.bottom p {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  margin-top: 10px;
  padding-block: 20px;
}

.role {
  display: flex;
  align-items: center;
  gap: 5px;
}

.role label {
  margin: 0;
  font-weight: 500;
}


@media (max-width: 1200px) {
  .options {
    grid-template-columns: repeat(3, 1fr);
  }

  .option {
    width: 20rem;
    max-width: 30rem;
    height: 8rem;
  }
}


@media (max-width: 900px) {
  .options {
    grid-template-columns: repeat(3, 1fr);
    
  }

  .option {
    width: 18rem;
    max-width: 30rem;
  }
}

@media (max-width: 768px) { 
  .options {
    grid-template-columns: repeat(2, 1fr);
  }

  .option {
    width: 25rem;
    max-width: 40rem;
  }
}


@media (max-width: 580px) { 
  .options {
    grid-template-columns: repeat(1, 1fr);
  }

  .option {
    width: 21rem;
    max-width: 40rem;
    margin: auto;
  }
 }

