.content {
  max-height: 50vh;
}

.form {
  width: 100%;
  padding: 1rem 0;
  margin-block: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: 20px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
}

/* .inputGroup + .inputGroup {
  margin-top: 10px;
} */

.inputGroup label {
  font-size: 0.9rem;
  font-weight: 600;
}

.inputGroup input,
.inputGroup select {
  border: thin solid var(--clr-off-white);
  padding: 0.2rem 0.5rem;
  font-size: 1rem;
  outline: none;
  border-radius: 6px !important;
  appearance: none;
}

.inputGroup select {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em - 2px),
    calc(100% - 15px) calc(1em - 2px), calc(100% - 2.5em) 0.2em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.inputGroup input:focus-within {
  background-color: inherit;
}

::placeholder {
  opacity: 0.5;
}

.placeholderImage {
  height: 100%;
  display: flex;
  align-items: center;
}

.placeholderImage img {
  max-width: 100%;
}

@media (max-width: 480px) {
  .form {
    width: 100%;
    padding: 0;
  }
}
