.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.header button {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hr {
  width: 100px;
  border-bottom: 2px solid var(--clr-primary) !important;
  margin-block: 10px;
}

.fileUpload {
  position: relative;
  text-align: center;
  margin: 30px auto 100px;
  width: 70%;
}

.fileUpload input {
  display: none;
}

.uploadIcon {
  font-size: 3rem;
}

.fileUpload label {
  grid-column: span 3;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--clr-primary);
  background-color: rgba(248, 248, 255, 1);
  padding: 3rem;
  cursor: pointer;
}

.fileUpload label.drag-active {
  background-color: var(--white);
}

.fileUpload label p {
  margin-block: 10px;
  font-size: 1.2rem;
}

.fileUpload label small {
  font-size: 0.8rem;
  color: var(--grey);
}

.uploadBtn {
  border: 0;
  background-color: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.dragFileElement {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.btn {
  border: 0;
  cursor: pointer;
  color: var(--clr-white);
  background-color: var(--clr-primary);
  border-radius: 6px;
  padding: 0.5rem 3rem;
  margin-top: 30px;
  display: block;
  position: absolute;
  right: 40%;
  left: 40%;
}
