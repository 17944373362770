.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    width: fit-content;
    margin-inline: auto;
    gap: 20px;
  }
  
  .pageLink {
    cursor: pointer;
    margin: 0;
    font-weight: 700;
    color: var(--grey);
  }
  
  /* pagination pageItem */
  .pageItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border: 1px solid #eaeaea;
    border-radius: 0.5rem;
    cursor: pointer;
  }
  
  /* pagination page item when active */
  .pageItemActive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border: 1px solid #eaeaea;
    border-radius: 0.5rem;
    cursor: pointer;
    background-color: var(--clr-primary);
  }
  
  .pageItemActive .pageLink {
    color: var(--clr-black);
  }
  
  .btn {
    padding: 0.2rem 0.5rem;
    cursor: pointer;
    border: 0;
    font-weight: 700;
  }
  
  .btn:disabled {
    color: var(--clr-grey);
  }