.prefill {
  height: 100vh;
  overflow-y: auto;
  background-color: #eee;
}

.prefill-container {
  width: 1024px;
  margin-inline: auto;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
}

.prefill-header img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.form-desc {
  border-radius: 8px;
  padding: 1.5rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-left: 5px solid var(--clr-primary);
}

.form-desc p {
  max-width: 90%;
}

.form-section {
  margin-bottom: 20px;
  padding: 1.5rem;
  border-radius: 8px;
  background-color: #fff;
  border-left: 5px solid var(--clr-primary);
}

.form-section .section-title {
  margin-bottom: 30px;
  font-size: 1.5rem;
}

.form-section .input-group {
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 10px;
  margin-bottom: 20px;
}

.form-section .input-group label {
  font-size: 1rem !important;
  font-weight: 600;
}

.form-section .input-group input {
  padding: 0.5rem;
  border-radius: 0 !important;
  border: none;
  border-bottom: 1px solid var(--clr-off-white);
  outline: none;
}

.offer-letter {
    height: 100vh;
}

.offer-letter-content {
    width: 60vw;
    height: 80vh;
    border-radius: 1rem;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
    padding: 1rem;
}

.offer-letter-container {
    width: 90%;
    margin-inline: auto;
    height: 100%;
    overflow-y: auto;
    padding: 1rem;
}

.company-logo h2 {
    font-size: 1.5rem;
    margin-bottom: 0;
}