@import url("https://fonts.googleapis.com/css2?family=Sigmar&display=swap");

body {
  zoom: 77%;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background: var(--clr-primary-fd);
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb:hover {
  background: var(--clr-primary-hover);
}

.signature {
  min-height: 100px;
  display: flex;
  align-items: center;
}

.signature h3 {
  font-family: "Sacramento", cursive;
}

.brand-logo {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.brand-logo a {
  color: var(--clr-black);
  text-decoration: none;
}

.brand-logo a:hover {
  color: var(--clr-black);
}

.logo {
  font-size: 18px;
  display: flex;
  align-items: center;
  margin: 0;
  /* color: var(--clr-primary); */
}

.company-name {
  font-family: var(--ff-logo);
  font-weight: 700;
  font-size: 2rem;
}

.logo-container {
  width: fit-content;
}

.logo-container + p {
  font-size: 14px;
}

.image-holder {
  width: clamp(20vw, 500px, 50vw);
  height: clamp(20vh, 500px, 50vh);
}

.form-border {
  box-shadow: var(--box-shadow);
  border-radius: 8px;
}

.myBtn,
.myBtn-back,
.myBtn-danger {
  background-color: var(--clr-primary);
  color: var(--clr-white);
  padding: 22px !important;
  outline: none !important;
  box-shadow: none;
  border-radius: 6px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myBtn-back {
  background-color: transparent;
  color: var(--clr-grey);
}

.myBtn-back:hover {
  background-color: #eee;
}

.myBtn-danger {
  background-color: var(--clr-red);
}

.myBtn-close,
.myBtn-reveal {
  background-color: transparent !important;
  color: var(--clr-red) !important;
  outline: none !important;
  border: none !important;
  position: absolute;
  top: 5px;
  right: 0.7rem;
  transition: 0.2s ease-in-out;
  box-shadow: none !important;
  padding: 0;
}

.myBtn-reveal {
  color: var(--clr-primary) !important;
  right: 3rem;
}

.myBtn-close svg,
.myBtn-reveal svg {
  font-size: 1.4rem;
}

.myBtn-close:hover,
.myBtn-reveal:hover {
  /* background-color: var(--clr-primary-hover) !important; */
  color: var(--clr-red) !important;
  font-weight: 600 !important;
  transform: scale(1.3);
}

.myBtn-reveal:hover {
  color: var(--clr-primary) !important;
}

.myBtn:hover {
  background-color: var(--clr-primary-hover) !important;
  color: var(--clr-white) !important;
}

.myBtn-danger:hover {
  background-color: transparent !important;
  color: var(--clr-red) !important;
  transform: scale(0.98);
  border-color: var(--clr-red) !important;
}

.grey {
  background-color: var(--clr-grey);
}

.confirmation-message {
  padding: 1rem 0;
  width: 100%;
}

.confirmation-message p {
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 400;
}

.table-btn {
  background-color: inherit;
  margin-left: 0.8rem;
  padding: 0;
  color: var(--clr-primary) !important;
}

.table-btn svg {
  font-size: 1.2rem;
}

.greeting {
  font-weight: 500;
  font-size: 2rem;
  background-position: left 70%;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 10vh;
  display: flex;
  padding: 2rem;
  color: var(--clr-primary);
  flex-direction: column;
  /* align-items: center; */
  /* gap: 10px; */
}
.greeting_2 {
  font-weight: 500;
  font-size: 2rem;
  background: url(/public/assets/Rectangle\ 2079.png);
  background-position: left 70%;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 10vh;
  display: flex;
  padding: 2rem;
  color: var(--clr-primary);
  /* flex-direction: column; */
  /* align-items: center; */
  /* gap: 10px; */
}

.modulename {
  height: 20vh;
  min-height: 200px;
  /* padding: 1rem 3rem; */
  background-color: var(--clr-primary);
  color: var(--clr-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.modulename h2,
.modulename h3 {
  color: inherit;
}

/* ANIMATIONS */
.fade-in {
  animation: slideIn 0.5s ease-in;
}

@keyframes slideIn {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 480px) {
  p {
    font-size: 1rem;
  }

  .steps-content {
    width: 100%;
  }

  .steps-action {
    justify-content: center;
  }

  .ant-steps-vertical {
    flex-direction: row !important;
  }

  .confirmation-message {
    padding: 1rem;
    width: 100%;
    text-align: center;
    margin-block: 20px;
  }
}

.documents,
.document-category a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.documents {
  height: calc(80vh - 230px);
  overflow-y: auto;
}

.document-category {
  width: 100%;
  margin-bottom: 20px;
}

.document-category a {
  color: var(--clr-primary);
  gap: 0 !important;
  padding: 1rem;
}

.document-category a svg {
  font-size: 3rem;
}

.document-category a p {
  font-size: 0.9rem !important;
  max-width: 60%;
}

.document-lists {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  margin-top: 30px;
}

.document-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1rem;
  position: relative;
  width: 12rem;
}

.document-delete {
  position: absolute;
  border: 0;
  background-color: transparent;
  right: 5px;
  top: 5px;
  color: var(--clr-red);
}

.document-delete svg {
  font-size: 1.5rem;
}

.document-item p {
  max-width: 80%;
  font-size: 1rem !important;
  line-height: 1;
}

.document-item a svg {
  font-size: 4rem;
  color: var(--clr-primary);
}

.approval_side_checkbox {
  position: relative;
}

.side_check {
  position: absolute;
  padding: 0px !important;
  top: -2px;
  right: 14px;
  width: 16px !important;
  height: 16px !important;
  border-radius: 0px !important;
}

.side_check::before {
  width: 10px !important;
  height: 10px !important;
}

.no-content-box {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-content-box img {
  width: 30%;
}

.no-content-box button {
  width: fit-content;
}

.card {
  border-radius: 10px !important;
}

.nok-card {
  background: linear-gradient(
    180deg,
    var(--clr-primary) -200%,
    rgba(255, 255, 255, 0) 70%
  );
  background-size: cover;
  border-radius: 10px !important;
  padding-block: 3rem !important;
}

.calendar {
  border: none;
  box-shadow: var(--box-shadow);
  padding: 1rem;
  border-radius: 6px;
}

.holiday-list {
  margin-top: 20px;
}

.holiday-list li {
  padding: 0.5rem 1rem;
  box-shadow: var(--box-shadow);
  border-radius: 6px;
  margin-bottom: 10px;
}

.idle {
  background-color: rgba(255, 255, 255, 0.9) !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  position: fixed;
  padding: 1rem;
  border-radius: 8px;
  bottom: 20px;
  left: 20px;
  z-index: 999999999999;
  max-width: 300px;
}

.not-found {
  /* height: calc(100vh - 170px); */
  display: flex;
  align-items: center;
}

.not-found p {
  max-width: 85%;
  font-weight: 600;
}

.error-page {
  width: 100vw;
  height: 100vh;
}

.error-page .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 20px;
}

.error-page h1 {
  font-size: 10rem;
  font-family: "Sigmar", cursive;
  color: transparent;
  background-clip: text;
  background-image: url("https://www.shutterstock.com/image-vector/abstract-futuristic-cyberspace-hacked-array-260nw-1095359921.jpg");
}

.team-members {
  max-height: 250px;
  overflow-y: auto;
}

.team-member h4 {
  font-size: 1rem;
}

.team-member p {
  font-size: 0.8rem !important;
}

.pulsating-animation {
  display: inline-block; /* Ensure proper alignment */
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
}

.menu_icon {
  display: none;
}  

.mobile_header {
  display: none;
}

.add_company_form {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: end;
}

.button_container {
  align-items: center;
  justify-content: center;
  gap: 1rem;
  display: flex;
  margin-top: 5rem;
}

@media (max-width: 1200px) {
  .hoc_header {
    display: none;
  }

  .mobile_header {
    display: flex;
    padding: 1rem;
    gap: 10px;
  }

  .menu_icon {
    display: block;
    cursor: pointer;
  }

  .side_text {
    color: var(--clr-white);
  }
}


@media (max-width: 768px) {
  .form-content {
    display: flex;
    flex-direction: column;
  }

  .add_company_form {
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 20px;
 }

 .table_container {
  width: 100%;
 }

 .responsive-table {
  width: 100%;
  overflow-x: auto;
}

.button_container {
  margin: 3rem auto 0;
  gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr)
}

.myBtn { 
   padding: 10px !important;
   /* margin: auto; */
}

@media (max-width: 768px) {
  .table-responsive table {
    width: 100%;
    min-width: unset; 
  }
}

}


@media (max-width: 400px) {
  .button_container {
    gap: 0.5rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr)
  }
  .myBtn { 
     padding: 10px !important;
    /* margin: auto; */
 }
}