.main,
.group {
  display: flex;
}

.main {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.group {
  flex-direction: column;
  gap: 10px;
}

.group {
  width: 50%;
}

.group label,
.users label {
  font-weight: 500;
}

.select {
  /* border-color: var(--clr-grey) !important; */
  outline: none !important;
  cursor: pointer !important;
  width: 100% !important;
}

.select:hover {
  /* border-color: var(--clr-grey) !important; */
  outline: 0;
}

.users_section input {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding: 0.3em 0.5em;
  width: 30%;
  margin-bottom: 30px;
  outline: none;
}

.users_section input::placeholder {
  font-size: 1rem;
  opacity: 0.5;
}

.users_cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.card {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  /* grid-template-columns: repeat(3, 1fr); */
  align-items: center;
  width: fit-content;
  gap: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  /* width: 100%; */
}

.card h4 {
  font-size: 1rem;
  text-align: end;
}

.details {
  /* grid-column: span 2; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
}

.details button {
  align-self: flex-end;
  background-color: var(--clr-primary);
  color: var(--clr-white);
}

.users {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: .5em;
  flex-wrap: wrap;
  margin-bottom: 20px;
  gap: 10px;
}

.selectedUser {
  padding: .2em .5em;
  border: 1px solid var(--clr-grey);
  /* width: fit-content; */
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--clr-off-white);
  /* color: var(--clr-white); */
  border-radius: 6px;
  font-weight: 500;
}

.selectedUser button {
  background-color: transparent;
  border: 0;
  font-weight: 700;
  font-size: 12px;
  padding: 0;
  color: var(--clr-black);
}
