.profile {
  display: grid;
  grid-template-columns: 300px repeat(11, 1fr);
  gap: 20px;
  padding-top: 2rem;
}

.sidebar,
.content {
  height: calc(100vh - 220px);
  overflow-y: unset;
  /* overflow-x: hidden; */
}

.content {
  grid-column: span 11;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.title h4 {
  width: 100%;
  font-weight: 600;
}
.title p {
  width: 100%;
  max-width: 700px;
  font-size: 1rem;
  font-weight: 500;
}

.title svg {
  font-size: 4rem;
}

.title button {
  background-color: var(--clr-primary);
  color: var(--clr-white);
  border: none;
}

.editContent {
  margin-top: 30px;
  padding: 1.5rem;
}

.nav__links {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
  padding-inline: 2rem !important;
}

.nav__links li {
  padding-top: 1rem;
}

.nav__links a {
  color: var(--clr-grey);
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav__links a svg {
  font-size: 1.5rem;
  color: var(--clr-black);
  opacity: 0.3;
  transform: rotate(90deg);
  transition: 0.5s ease-in;
}

.nav__links a.active {
  color: var(--clr-primary) !important;
}

.nav__links a.active svg {
  color: var(--clr-primary) !important;
  opacity: 1;
}

.profilePhoto {
  width: 250px;
  position: relative;
}

.imageContainer {
  width: 250px;
  height: 250px;
}

.avatar,
.image {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  object-position: top;
  transition: 0.5s ease;
  backface-visibility: hidden;
  cursor: pointer;
}



/* .hoverState {
  position: absolute;
  opacity: 0;
  transition: 0.5s ease;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
}

.imageContainer:hover .hoverState {
  opacity: 1;
} */

.modalImage {
  height: 60vh;
  object-fit: contain;
  border-radius: 8px;
  margin-inline: auto;
}

.newUpload {
  position: relative;
  max-width: 250px;
}

.pencil {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0.5rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0 50%;
  width: 40px;
  height: 40px;
  box-shadow: -2px 4px 4px 0px #00000040;
}

.pencil svg {
  transition: 0.2s ease-in-out;
}

.pencil:hover {
  color: var(--clr-black) !important;
  border-color: rgba(0, 0, 0, 0.05) !important;
}

.pencil:hover svg {
  transform: scale(1.1);
}


.pencil svg {
  font-size: 1.5rem;
}

.upload {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}

.contentBody {
  padding-block: 1rem;
  margin-top: 10px;
}

.contentBody label {
  font-size: 0.75rem;
}

.contentBody p {
  font-size: 1rem;
  font-weight: 500;
}

.form {
  width: 100%;
}

.form label {
  font-weight: 500;
  font-size: 1rem;
  color: var(--clr-grey);
  margin: 0;
}

.form input,
.form textarea,
.form select,
.fileUpload {
  border: 1px solid var(--clr-off-white);
  padding: 0.3rem 1rem;
  border-radius: 6px !important;
  margin-top: 7px;
  outline: none;
  width: 100%;
}

.form option {
  font-size: 1rem !important;
}

.fileUpload {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.fileUpload svg {
  font-size: 5rem;
  margin-inline: auto;
}

.formBtn {
  margin-top: 20px;
  background-color: var(--clr-primary);
  color: var(--clr-white);
  border: none !important;
  width: 100%;
}

.formBtn:hover {
  background-color: var(--clr-primary);
  color: var(--clr-white) !important;
  transform: scale(0.98);
}

.medical,
.emergency {
  background-color: var(--clr-white);
  padding: 1.5rem;
}

.sign {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.signature {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  height: 150px;
}

.signature img {
  object-fit: contain;
  width: 300px;
  height: 150px;
  align-self: center;
  margin: 30px auto;
}

.sign button {
  align-self: flex-end;
}

.cloudBtn svg {
  font-size: 2rem;
  opacity: 0.7;
  color: var(--clr-primary);
  cursor: pointer;
}

.cloudBtn svg:hover {
  opacity: 1;
}

.cardIcon {
  font-size: 2rem;
}

.cardAvatar {
  box-shadow: 0px 4px 4px 0px #00000040;
  color: var(--clr-primary) !important;
  font-weight: 600;
}

.cardDetails label {
  color: var(--clr-primary);
  font-weight: 700;
  font-size: 1rem;
}

.cardDetails h5 {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.cardDetails p {
  font-size: 1rem;
}

.cardDetails svg {
  color: var(--clr-primary);
}

.ctaBtns {
  position: absolute;
  top: 10%;
  right: 15px;
}

.noDocumentImage {
  object-fit: contain;
  width: 30%;
}

.documentMain {
  height: calc(80vh - 200px);
}

.folderactive {
  filter: grayscale(0) !important;
  transform: scale(1.2);
  background-color: rgba(0, 0, 0, 0.05);
}

.uploadBtn {
  border: 0;
  outline: none;
  background-color: inherit;
  cursor: pointer;
  font: inherit;
  display: flex;
  align-items: center;
  font-size: 1rem;
  gap: 10px;
  font-weight: 500;
  box-shadow: none;
  margin-top: 10px;
}

.uploadBtn svg {
  font-size: 1.5rem;
  color: var(--clr-primary);
}

.uploadBtn:hover {
  background-color: transparent !important;
  color: var(--clr-black) !important;
}

.addEntryBtn {
  color: var(--clr-primary);
  font-size: 6rem;
  opacity: 0.8;
}

.addEntryBtn:hover {
  opacity: 0.5;
}

@media (max-width: 1200px) {
  .profile {
    padding-top: 1rem;
    overflow-x: hidden;
    height: 100%;
  }
  
  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 20rem;
    z-index: 9999;
    background: var(--clr-primary);
    transition: var(--transition-default);
    transform: translateX(-100%);
  }

  .sidebarToggle {
    transform: translateX(0);
    transition: var(--transition-default);
  }

  .nav__links {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
    padding-inline: 2rem !important;
  }
  
  .nav__links li {
    padding-top: 1rem;
  }
  
  .nav__links a {
    color: var(--clr-white);
    font-weight: 500;
  }
  
  .nav__links a svg {
    font-size: 1.5rem;
    color: var(--clr-white);
    opacity: 0.3;
    transform: rotate(90deg);
    transition: 0.5s ease-in;
  }
  
  .nav__links a.active {
    background-color: var(--clr-white);
    color: var(--clr-primary) !important;
    padding: 1rem 2rem 1rem 0.6rem;
    border-radius: 0.5rem;
  }
  
  .nav__links a.active svg {
    color: var(--clr-primary) !important;
    opacity: 0;
  }

  .redirectIcon {
    color: var(--clr-off-white);
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    align-items: center;
    margin-top: 3rem;
    width: 100%;
    justify-content: space-between;
  }

  .sidebar_text {
    color: var(--clr-white);
    gap: 10px;
  }

  .sidebar_text span {
    margin-left: 10px;
  }
  
  .profilePhoto {
    width: 250px;
    position: relative;
    margin: auto;
  }
}