.request_employee_body {
  width: 100%;
  display: flex;
  gap: 30px;
}
.request_employee_body section {
  flex: 1;
}
.request_emplyee_head {
  font-size: 20px !important;
  padding: 1rem;
}
.leaveMain {
  padding-bottom: 2rem;
  width: 100%;
}
.body {
  width: 100%;
  background: #fff;
  margin-bottom: 3rem;
}
.leaveContainer {
  width: 568px;
  height: auto;
  padding: 0 59px;
  margin-bottom: 3rem;
}
.idContainer {
  height: auto;
  padding: 0 59px;
  margin-bottom: 3rem;
}
.newLeaveHead {
  line-height: 24.2px;
  font-size: 20px;
  font-weight: 600;
}

.newLeaveCat {
  margin-bottom: 30px;
}

.newLeaveCattext_hr{
  font-size: 14px !important;
}
.line {
  height: 1px;
  background-color: rgba(14, 8, 90, 0.21);
  margin: 30px 0;
}
.newLeaveCat h4 {
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
}
.newLeaveCatWrapper_allrequest {
  display: flex;
  /* grid-template-columns: repeat(auto-fill, minmax(25%, 1fr)); */
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
}
.newLeaveCatWrapper_allrequest article {
  width: 120px;
  min-width: max-content;
  flex-basis: 20%;
  height: 90px;
  border-radius: 5px;
  background: rgba(14, 8, 90, 0.21);
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  /* border: 2px solid #16166C; */
}

.active__box_allrequest {
  border: 2px solid var(--clr-primary);
}
.newLeaveCatWrapper_allrequest article p {
  font-weight: 600;
  font-size: 18px !important;
  line-height: 14.52px;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}
.allrequest_no {
  margin: 5px 0;
}
.newLeaveCattext_allrequest {
  font-weight: 400;
  font-size: 14px;
  margin-top: 2px;
}
.newLeaveCatBox_allrequest {
  width: 14px;
  height: 14px;
  border-radius: 0px 5px 0px 5px;
  position: absolute;
  top: -1px;
  right: -1px;
  background-color: var(--clr-primary);
  display: none;
}
.active_box_allrequest {
  display: inline-flex;
}
.leaveImage {
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;
}
.leaveImage img {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
}
.avatar {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 4px solid var(--clr-primary);
}

.leaveAttachFile_allrequest h4,
.description_allrequest {
  font-size: 14px;
  line-height: 14.52px;
  font-weight: 600;
}
.leaveAttachFile_allrequest p,
.descriptionText_allrequest {
  font-size: 12px !important;
  line-height: 12.1px;
}
.leaveInputFile {
  width: 100%;
  height: 103px;
  border: 1px dashed rgba(0, 0, 0, 1);
  padding: 7px 20px 7px 20px;
  border-radius: 5px;
  background: rgba(217, 217, 217, 0.35);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  margin: 10px auto;
}
.leaveInputFile img {
  width: 43px;
  height: 43px;
}
.leaveInputFile p {
  font-size: 8px;
}
.leaveFileUpload {
  display: none;
}
.performance-form label {
  font-size: 12px !important;
  /* color: red; */
}
.leaveForm_allrequest label {
  font-size: 12px !important;
}
.leave__dropdown {
  color: inherit !important;
  font-weight: inherit;
  font-size: 12px !important;
}

.leave__dropdown.active {
  color: var(--clr-primary) !important;
  font-weight: 900 !important;
}

.label__leave {
  font-size: 12px !important;
}
.input_leave {
  font-size: 12px !important;
  font-weight: bold !important;
}
.input_leave:disabled {
  cursor: not-allowed !important;
}
#label__leave_allrequest {
  font-size: 14px !important;
  font-weight: bold !important;
}
.leaveForm_allrequest button {
  width: 120px;
  height: 40px;
  background: var(--clr-primary);
  border-radius: 5px;
  color: #fff;
}
.leaveForm_allrequest button:hover {
  background: var(--clr-primary-hover);
  color: #fff;
}


@media (max-width: 768px) {
  .request_employee_body {
    display: flex;
    flex-direction: column;
  }
}
