.management {
  padding: 1rem;
  margin-top: 30px;
}

.entry p {
  margin-bottom: 5px;
  font-size: 1rem;
}

.entry h4 {
  font-size: 1.2rem;
}
