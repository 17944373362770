.teamLeave_main h4{
    text-transform: capitalize;
}
.teamLeave_line{
    margin: 1.3rem 0 2rem 0;
}
.teamAnnual_table td {
    text-transform: capitalize;
    padding: 1rem !important;
}
.teamAnnual_table th{
    text-transform: capitalize;
    /* font-size: 12px; */
}