.navbar {
  height: 70px;
  position: fixed;
  width: 100%;
  /* top: 100px; */
  z-index: 200;
  /* background-color: #1c315e; */
}

.navbar div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar div,
.navlinks, 
.navlinks li,
.navlinks li a {
  height: 100%;
}

.navlinks {
  margin: 0;
}

.navlinks li a {
  display: flex;
  align-items: center;
}

.navlinks,
.navlinks li {
  display: flex;
  align-items: center;
}

.myProfile li,
.myProfile .navlinks li a {
  /* background-color: var(--clr-primary); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.myProfile li:not(:nth-child(1)) {
  padding: 0.5rem 1.5rem;
  color: var(--clr-white);
  cursor: pointer;
}

.navlinks li a,
.navlinks li a:active,
.navlinks li a:hover {
  color: var(--clr-primary);
  font-weight: 500;
  padding: 0.5rem 1.5rem;
}

.selected {
  color: var(--clr-primary) !important;
  background-color: var(--clr-white) !important;
}

.myProfile svg {
  font-size: 1.1rem !important;
}

.modalIcon {
  font-size: 4rem;
  text-align: center;
}

/* ayo */
.dropleave {
  width: 610px;
  height: 266px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  padding: 47px 37px;
  cursor: default !important;
}
.dropleave h4 {
  font-size: 12px;
  font-weight: 700;
}
.dropleave li {
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
.leftDropLeave {
  width: 170px;
  height: 170px;
}
.middleDropLeave,
.rightDropLeave {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.middleDropLeave article,
.rightDropLeave article {
  min-width: 132px;
  min-height: 63px;
  display: block;
}
.faq {
  font-size: 20px;
  font-weight: 700;
}

.ant-dropdown-trigger .active {
  background-color: var(--clr-white) !important;
  color: var(--clr-primary) !important;
}

.actionLists li:hover {
  text-decoration: underline;
  color: var(--clr-primary) !important;
}





@media (max-width: 580px) {
  .navlinks {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    position: fixed;
    bottom: 0; 
    left: 0; 
    right: 0;
    height: 5rem;
    background-color: #efefef;  
    padding: 10px 0; 
  }

  .dropleave {
    position: relative;
    right: 1rem;
    top: 0;
    bottom: 20rem;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 30px;
    overflow: auto;
  }


  
}










