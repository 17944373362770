.my-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin-inline: auto;
}

.card__border__bottom {
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  padding: 0 0 40px 0;
}

.planning__form__wrapper {
  display: flex;
  padding: 25px 0px;
  width: 100%;
  flex-direction: column;
  gap: 10px;
}

.planning__form__top__buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.planning__form__top__buttons span {
  width: fit-content;
}

.planning__form {
  width: 100%;
  border: 2px solid black;
  position: relative;
}

.planning__form__title {
  background-color: var(--clr-white);
  position: absolute;
  top: -10px;
  left: 14px;
  padding: 0px 15px;
}

.planning__form__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 40px;
  gap: 20px;
}

.planning__form__content .planning__form__item__first__half {
  grid-column: 1 / 2;
  width: 100%;
}

.planning__form__item__first__half .ant-picker,
.planning__form__item__second__half .ant-picker,
.appraisal__settings__form__grid .ant-picker {
  border: 2px solid black;
  padding: 10px !important;
}

.planning__form__item__second__half {
  grid-column: 2 / 3;
}

.planning__form__content .planning__form__item {
  grid-column: 1 / 3;
}

.target__form__group,
.goal__form__group {
  display: flex;
  border: 2px solid black;
  border-radius: 10px;
  overflow: hidden;
}

.goal__form__group .ant-select {
  border-top: none !important;
  border-bottom: none !important;
  border-right: 2px solid black !important;
  border-left: none !important;
  border-radius: 0px !important;
  outline: none !important;
  width: 100%;
}

.hris_form_group {
  display: flex;
  border: 2px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
}

.hris_form_group .ant-select,
.hris_form_group select {
  border-top: none !important;
  border-bottom: none !important;
  border-right: 0px solid black !important;
  border-left: none !important;
  border-radius: 10px !important;
  outline: none !important;
  width: 100%;
}

.hris_form_group select {
  margin-top: 0px !important;
}

.hris_form_group .ant-select-selector {
  border: none !important;
}

input[type="number"] {
  padding: 7px 11px;
}

input[type="number"]:focus {
  box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
  outline: 0;
}

.target__form__group .ant-select {
  border-top: none !important;
  border-bottom: none !important;
  border-right: 2px solid black !important;
  border-left: 2px solid black !important;
  border-radius: 0px !important;
  outline: none !important;
}

.target__form__group input {
  outline: none;
  border: none;
}

.target__form__group input:focus {
  outline: none;
  border: none;
  border-radius: 0px !important;
}

.target__table {
  padding: 10px 0;
}

.unit__measure__form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-bottom: 1px solid #d9d9d9;
  padding: 2rem 0;
}

.unit__measure__form__group {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.unit__measure__form__group label {
  font-weight: 600;
  font-size: 18px !important;
  width: 30%;
}

.unit__measure__table {
  padding: 2rem 0;
}

.approval__header {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-bottom: 1px solid #d9d9d9;
  padding: 1.5rem 0;
}

.approval__nav .dropdown_links {
  display: flex;
  gap: 1rem;
}

.approval__nav .dropdown_links a {
  color: var(--clr-black);
  padding: 10px;
}

.approval__nav .dropdown_links .active {
  color: var(--clr-primary);
  border-bottom: 3px solid var(--clr-primary);
  padding: 10px;
  font-weight: 500;
}

.cascade_navlinks a {
  text-decoration: none;
  padding: 1rem 2rem;
  color: #000;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: border 0.3s, color 0.3s;
}

.cascade_navlinks a:hover {
  border-color: var(--clr-primary);
  color: var(--clr-primary);
}

.cascade_navlinks .active {
  background-color: var(--clr-primary);
  color: #fff;
  border-color: var(--clr-primary);
}

.cascade_navlinks .active:hover {
  color: #fff;
  border-color: var(--clr-primary);
}

.monthly__approval__list {
  padding: 1rem 0;
}

.planning__cascade__header {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 0 2rem 0;
  border-bottom: 1px solid #d9d9d9;
}

.planning__cascade__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 2.5rem 0;
}

.disabled__input {
  background-color: #d9d9d9 !important;
  color: black !important;
}

.planning__cascade__table {
  padding: 2rem 0;
}

.planning__history__header {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 0 2rem 0;
  border-bottom: 1px solid #d9d9d9;
}

.planning__history__tree {
  padding: 2rem 0;
  width: 100%;
}

.planning__history__tree .ant-tree-switcher svg {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.planning__history__tree .ant-tree-node-content-wrapper {
  border: 1px solid #0000004d;
  border-radius: 0px !important;
  background-color: #d9d9d9;
  padding: 0.8rem;
  width: 100%;
}

.planning__history__treenode {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.monitoring__individual__details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 20px;
  padding: 2.5rem 0;
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
}

.monitoring__individual__content {
  padding: 2rem 0;
}

.monitoring__individual__content__header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.monitoring__individual__content__header .ant-picker {
  padding: 10px !important;
}

.monitoring__individual__content__table {
  padding: 3rem 0;
}

.create__appraisal__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 20px;
  padding: 2.5rem 0;
  width: 100%;
}

.create__date .ant-picker {
  padding: 10px !important;
}

.create__appraisal__table__tabs {
  padding: 2rem 0;
}

.create__appraisal__table__tabs .ant-tabs-tab {
  color: var(--clr-primary);
}

.manage__team__member__textareas {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 40px;
  padding: 2.5rem 0;
  width: 80%;
  margin: 0 auto;
}

.manage__team__checkbox .ant-checkbox + span {
  font-size: 1rem !important;
}

.manage__team__checkbox .ant-checkbox-group {
  column-gap: 20px;
  row-gap: 10px;
}

/* .appraisal__settings__table .ant-checkbox {
  background-color: red !important;
} */

.task__checkbox__upcoming .ant-checkbox {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.task__checkbox .ant-checkbox {
  width: 2rem;
  height: 2rem;
  border: 3px solid #579943;
  border-radius: 50% !important;
  overflow: hidden !important;
}

.task__checkbox .ant-checkbox-wrapper {
  border-radius: 50% !important;
}

.task__checkbox .ant-checkbox-inner {
  width: 100%;
  height: 100%;
  border-radius: 50% !important;
  border: 0px !important;
}

.task__checkbox .ant-checkbox-wrapper input:checked + span {
  background-color: #579943;
  border-color: #579943;
  color: white;
  border: 0px !important;
}

.task__checkbox .ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: #579943 !important;
}

.task__checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #579943 !important;
}

.task__checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
  width: 0.5rem;
  height: 1.3rem;
}

.appraisal__checkbox__wrapper {
  display: flex;
  justify-content: center;
}

.appraisal__checkbox__wrapper .ant-checkbox {
  width: 2rem;
  height: 2rem;
  border-radius: 50% !important;
  overflow: hidden !important;
}

.appraisal__checkbox__wrapper .ant-checkbox-wrapper {
  border-radius: 50% !important;
}
/* 
.appraisal__checkbox__wrapper .ant-checkbox-input {
  border-radius: 50% !important;
  overflow: hidden !important;
} */

.appraisal__checkbox__wrapper .ant-checkbox-inner {
  width: 100%;
  height: 100%;
  border-radius: 50% !important;
}

.appraisal__checkbox__wrapper .ant-checkbox-wrapper input:checked + span {
  background-color: #579943;
  border-color: #579943;
  color: white;
}

.appraisal__checkbox__wrapper .ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: #579943 !important;
}

.appraisal__checkbox__wrapper .ant-checkbox .ant-checkbox-inner:hover {
  border-color: #579943 !important;
}

.appraisal__checkbox__wrapper .ant-checkbox-checked .ant-checkbox-inner:after {
  width: 0.5rem;
  height: 1.3rem;
}

.appraisal__settings__form__header {
  width: 60%;
}

.appraisal__settings__form__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 40px 0;
  gap: 20px;
}

.appraisal__settings__form__enforcement .ant-checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.appraisal__settings__form__enforcement .ant-checkbox + span {
  font-size: 18px;
  font-weight: 500;
}

.begin__appraisal__details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 20px;
  padding: 2.5rem 0;
  width: 100%;
}

.monitoring__appraisal__details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 20px;
  padding: 1.5rem 0;
  width: 100%;
}

.begin__appraisal__content__form {
  border-top: 1px solid #d9d9d9;
  margin-top: 3rem;
}

.begin__appraisal__content__table {
  padding: 3rem 0;
}

/* .begin__appraisal__content__table .ant-table .ant-table-cell,
.monitoring__individual__content__table .ant-table .ant-table-cell,
.performance_table .ant-table .ant-table-cell {
  border: 1px solid #140202;
} */

.begin__appraisal__content__table
  .ant-table
  .ant-table-cell
  .ant-input-group-addon,
.monitoring__individual__content__table
  .ant-table
  .ant-table-cell
  .ant-input-group-addon,
.planning__form__item__second__half .ant-input-group-addon {
  background-color: var(--clr-primary);
  color: white;
}

.begin__appraisal__content__table__comment {
  display: flex;
  justify-content: center;
  color: #0020f4;
  cursor: pointer;
}

.begin__appraisal__content__table__comment__divider {
  height: 1;
  width: 2px;
  background-color: lightgray;
}

.create__request__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0;
}

.create__request__form__grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 20px;
  padding: 1rem 0;
  width: 100%;
}

.form__item {
  border-radius: 0.5rem;
  border-left: 9px solid var(--clr-primary);
  box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.3);
  width: 45rem;
  max-width: 50rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.form__item__footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 2px solid rgba(0, 0, 0, 0.3);
}

.form__item__footer__divider {
  height: 1.6rem;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.3);
}

.form__item__side__menu {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 1rem;
  width: 3.5rem;
  height: fit-content;
  padding: 1rem 0;
  border-radius: 0.5rem;
  box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.3);
}

.make__request__form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  margin: 0 auto;
}

.make__request__form__title {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  padding: 0 1rem;
  box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.3);
  border-top: 5px solid var(--clr-primary);
}

.make__request__form__item {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
  box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.3);
}

.track_request_card {
  border-radius: 0.5rem;
  box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.3);
  /* width: 60rem; */
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
}

.track__request__steps {
  width: 40rem;
}

.predefined__template__container {
  border-radius: 0.5rem;
  box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.3);
  width: 100%;
  /* max-width: 80rem; */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin: 0 auto;
}

.predefined__template__header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.email__phone__external__survey {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.email__phone__external__survey .ant-form-item {
  flex: 1;
}

.todo__tasks__container {
  width: 100%;
  max-width: 50rem;
  /* margin: 0 auto; */
}

.request__steps .ant-steps .ant-steps-item-icon .ant-steps-icon {
  color: white;
}

.ant-upload-wrapper .ant-upload-drag {
  padding: 10px;
}

.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions
  .ant-upload-list-item-action {
  opacity: 1;
}

.ant-drawer-body {
  overflow-y: scroll;
}

.ant-drawer-body::-webkit-scrollbar {
  width: 10px;
}

.ant-drawer-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ant-drawer-body::-webkit-scrollbar-thumb {
  background: var(--clr-primary);
}

.cascade__edit__drawer .ant-drawer-header {
  align-items: start !important;
}

.add__task__modal {
  height: fit-content !important;
}

.add__task__modal .ant-modal-content,
.add__task__modal .ant-modal-footer {
  padding: 0 !important;
  margin: 0 !important;
}

.add__task__modal .add__task__input {
  width: 90%;
  border: none;
  padding-left: 0 !important;
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  color: black;
}

.add__task__modal .add__task__input:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.add__task__modal .add__task__input::placeholder {
  font-size: 1.2rem;
  font-weight: 600;
  color: darkgray;
}

.add__task__modal .add__task__description {
  width: 90%;
  border: none;
  padding-left: 0 !important;
  font-size: 0.8rem !important;
  color: black;
}

.add__task__modal .add__task__description:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.add__task__modal .add__task__description::placeholder {
  font-size: 0.8rem;
  font-weight: 500;
  color: darkgray;
}

@media (max-width: 768px) {
  .track_request_card {
    width: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: start;
    overflow: auto;
  }

  .track__request__steps {
    width: 22rem;
  }
}


@media (max-width: 580px) {
  .form__item {
    width: 20rem;
    max-width: 35rem;
    padding: 0.5rem; 
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
.create__request__form__grid {
  grid-template-columns: repeat(2, 1fr);
}
}