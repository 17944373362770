.welcome {
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* larger screens  */

/* @media (min-width: 768px) { */
.admin_layout {
  transition: all 0.25s ease-in-out;
  position: fixed;
  top: 4.5rem;
  width: 100%;
  height: 100%;
  background: rgba(216, 216, 216, 0.24);
  overflow: scroll;

}
.admin_layout.small__sidebar {
  grid-template-columns: 100px repeat(11, 1fr) !important;
}

.admin_layout.small__sidebar .performance__nav {
  overflow-x: hidden;
}

.admin_layout.small__sidebar .sidebar_text {
  display: none;
}
.admin_layout.small__sidebar .sidebar_text_hidden {
  visibility: hidden;
  order: 1;
}

.admin_layout_content {
  overflow-y: auto;
  background: rgba(216, 216, 216, 0.24);
  position: relative;
  top: 8.5rem;
  padding-bottom: 3rem;
  display: flex;
}

.backBtn{
  background: #bb2323 !important;
  color: #FFF !important;
}

.addMore{
  background: #b5b5b5 !important;
  color: #FFF !important;
}

.sidebar__menu {
  font-size: 1.75rem;
  padding-right: 8px;
  cursor: pointer;
}

.mobile-icon-toggler {
  display: none;
}
/* } */

/* mobile screens */
@media (max-width: 1200px) {
  .mobile-icon-toggler {
    display: block;
    cursor: pointer;
    padding: 10px;
  }
  .admin_layout_content {
    display: flex;
    flex-direction: column;
    top: 0.5rem;
  }
  .performance__nav {
    background: var(--clr-white);
    position: fixed !important;
    top: 20rem;
    /* display: none !important; */
    top: 0px;
    left: -200vw;
    height: 100vh;
    width: 100%;
    /* background-color: aqua !important; */
    /* left: calc(-10); */
    z-index: 9999999999999999999999999999999;
    transition: var(--transition-default);
  }

  .performance__nav.mobile-show-sidebar {
    /* right: 0px; */
    left: 0px;
  }
}
