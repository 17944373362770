/* .ant-dropdown-trigger.active {

    background-color: var(--clr-white) !important;
    color: var(--clr-primary) !important;
} */
.leaveMain {
    padding-bottom: 2rem;
    width: 100%;
}
.body{
    width: 100%;
    background: #FFF;
    margin-bottom: 3rem;
}
.leaveContainer{
    width: 568px;
    height: auto;
    padding: 0 59px;
    margin-bottom: 3rem;
}
.newLeaveHead{
    line-height: 24.2px;
    font-size: 20px;
    font-weight: 600;
}

.newLeaveCat{
    margin-bottom: 30px;
}
.line{
    height: 1px;
    background-color:  rgba(14, 8, 90, 0.21);
    margin: 30px 0;
}
.newLeaveCat h4{
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
}
.newLeaveCatWrapper{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(79px, 1fr));
    gap: 10px;

}
.newLeaveCatWrapper article{
    width: 85px;
    min-width: max-content;
    height: 65px;
    border-radius:5px;
    background: rgba(14, 8, 90, 0.21);
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    /* border: 2px solid #16166C; */
}

.active__box{
    border: 2px solid #16166C;
}
.newLeaveCatWrapper article p{
    font-weight: 600;
    font-size: 12px !important;
    line-height: 14.52px;
    display: flex;
    flex-direction: column;
    padding: 0 10px;
}
.newLeaveCattext{
    font-weight: 400;
    font-size: 10px;
}
.newrequest_no{
    font-size: 12px;
    margin-bottom: 3px;
}
.newLeaveCatBox{
    width: 12px;
    height: 12px;
    border-radius: 0px 5px 0px 5px;
    position: absolute;
    top: -1px;
    right: -1px;
    background-color: #16166C;
    display: none
}
.active_box{
    display: inline-flex;
}
.leaveImage{
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}
.leaveImage img{
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: cover;
   
}
.leaveImageText{
    font-size: 12px;
    font-weight: 700;
}
.avatar{
    width: 38px;
    height: 38px;
    border-radius:50%;
    border: 4px solid var(--clr-primary);
}
.leaveForm label{
    font-size: 10px !important;
}
.leaveAttachFile h4{
    font-size: 12px;
    line-height: 14.52px;
    font-weight: 600;
}
.leaveAttachFile p, .descriptionText{
    font-size: 10px !important;
    line-height: 12.1px;
}
.leaveInputFile{
    width: 100%;
    height: 103px;
    border: 1px dashed rgba(0, 0, 0, 1);
    padding: 7px 20px 7px 20px;
    border-radius: 5px;
    background: rgba(217, 217, 217, 0.35);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    margin: 10px auto
}
.leaveInputFile img{
    width: 43px;
    height:43px;
}
.leaveInputFile p{
    font-size: 8px;
}
.leaveFileUpload{
    display: none;
}
.performance-form label{
    font-size: 12px !important;
    /* color: red; */
}
.leaveForm label{
    font-size: 12px !important;
}
.leave__dropdown{
    color: inherit !important;
    font-weight: inherit;
    font-size: 12px!important;
}


.leave__dropdown.active {
    color:var(--clr-primary) !important;
}

.label__leave{
    font-size: 12px !important;
}

.input_leave{
    font-size: 12px !important;
    font-weight: bold !important;
}
.input_leave:disabled{
    cursor: not-allowed !important;
}
#backup_employee{
    width: 100% !important;
}
.backup_select{
    width: 100% !important;
}
.error{
    font-size: 10px !important;
    margin-left: 5px;
}