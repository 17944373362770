.notificationsList {
  max-height: 200px;
  /* overflow-y: auto; */
  width: 100%;
}

.card {
  display: flex;
  gap: 50px;
  margin-bottom: 30px;
  margin-top: 18px;
  width: 100%;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.left svg {
  font-size: 2rem;
  color: var(--clr-primary);
}

.left p {
  font-size: .8rem;
  margin-bottom: 0;
  text-align: center;
  font-weight: 500;
}

.notification_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 8px;
}

.notification_header h3 {
   font-size: 1rem;
   font-weight: 600;
}

.notificationCard {
  border-radius: 8px;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.removed {
  opacity: 0;
  transform: scale(0.95);
  pointer-events: none;
}


.markReadBtn {
  border: none;
  outline: none;
  box-shadow: none;
}

.markReadBtn p {
  font-weight: 500;
  color: blue;
  text-decoration: underline;
}


.notificationsLength {
  display: flex; 
  justify-content: flex-end;
  gap: 15px;
}

.notificationsLength p {
   font-size: 1.5rem;
   font-weight: 700;
   color: var(--clr-black);
}

.notificationsLength span {
   background-color: blue;
   color: var(--clr-white);
   border-radius: 100%;
   font-weight: 600;
   width: 25px;
   height: 25px;
   align-items: center;
   text-align: center;
}

.markAllAsRead {
  margin: auto;
  justify-content: center;
  position: relative;
  align-items: center;
  background: transparent !important;
  top: 10px;
  border: none;
  display: flex;
  font-size: 1rem;
  color: var(--clr-black);
  text-decoration: underline;
  font-weight: 700;
}


.icon {
  font-size: 17px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in;
}

.red {
  color: var(--clr-red);
}

.green {
  color: var(--clr-green);
}

.right {
  width: 100%;
  border-bottom: 3px solid #c1c0c0;
  padding-bottom: 1rem;
}



.right p {
  line-height: 1.2;
  font-size: .8rem;
  margin-bottom: 0;
  /* transition: 2s ease-in; */
}

.card:hover .icon {
  opacity: 1;
}

@media (max-width: 480px) {
  .card {
    gap: 20px;
  }

  .right h3 {
    font-size: 1.2rem;
  }

  .right p {
    font-size: 0.8rem;
  }
}
