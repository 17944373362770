/* steps custom styling */

.ant-steps-item-icon {
  background-color: var(--clr-primary) !important;
  border-color: var(--clr-primary) !important;
  color: var(--clr-white) !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--clr-white) !important;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: grey !important;
  border-color: grey !important;
}

.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: var(--clr-off-white) !important;
}

.ant-modal-title {
  font-weight: 600 !important;
  font-size: 1.5rem !important;
}
.qrcode_override {
  width: 30px !important;
  height: 30px !important;
  border-radius: 0;
  background-color: none !important;
  border: none !important;
  /* background: none !important; */
}

.ant-qrcode.qrcode_override.css-dev-only-do-not-override-diro6f {
  background-color: none !important;
  border: none !important;
}
:where(.css-dev-only-do-not-override-diro6f).ant-qrcode.qrcode_override {
  background-color: none !important;
  border: none !important;
}

.ant-modal-body {
  padding: 0 !important;
  /* padding: 1rem; */
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-modal-content {
  padding: 1rem !important;
}
.over .ant-modal-content {
  padding: 0rem !important;
}

.ant-modal-body::-webkit-scrollbar {
  display: none !important;
}

.ant-modal-close-icon {
  color: var(--clr-red);
}

.ant-modal-close:hover,
.ant-modal-close-x:hover {
  background-color: transparent !important;
  transform: scale(1.2);
  transition: 0.2s ease-in;
}

.ant-modal-footer {
  padding: 1rem;
}

.ant-modal-confirm-title {
  font-size: 1.3rem !important;
}

.ant-table-body::-webkit-scrollbar {
  display: none !important;
}

.steps-action {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.steps-content {
  width: 90%;
}

.ant-steps .ant-steps-item-icon {
  border-radius: 50% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-steps
  .ant-steps-item-custom
  > .ant-steps-item-container
  > .ant-steps-item-icon
  > .ant-steps-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.ant-btn-default:not(:disabled):hover {
  color: inherit;
  border-color: inherit;
}

#my-stepper .ant-steps-item-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

#my-stepper .ant-steps-item::before {
  background-color: #ccc !important;
}

.ant-list-item {
  padding-left: 0 !important;
}

.ant-input {
  margin: 0 !important;
}

.unit-div .ant-input {
  border-start-end-radius: 0 !important;
  border-end-end-radius: 0 !important;
}

.ant-input:hover {
  border-color: #ccc !important;
}

/* .anticon > * {
  margin-top: -8px !important;
} */

.bgLogin {
  background: url("../../public/assets/login_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  color: #1c315e;
}
.white {
  background: #fff !important;
}
.fullHeight {
  min-height: 70vh;
}
.multicolortext {
  background-image: linear-gradient(
    to left,
    violet,
    green,
    green,
    green,
    orange,
    orange,
    red
  );
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}

.b0 {
  border: none !important;
}
.fancy-header {
  width: 100%;
  border-top: 5px solid;
  border-image: linear-gradient(
      to right,
      grey 25%,
      yellow 25%,
      yellow 50%,
      red 50%,
      red 75%,
      teal 75%
    )
    5;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
  background-color: #fff !important;
}
.ant__number.ant-input-number-handler-wrap {
  /* border: none !important; */
  top: 6px !important;
  right: 2px !important;
  height: 38px !important;
}

.ant__number:active {
  box-shadow: none !important;
}

@media (max-width: 768px) {
  .ant-steps-item-content {
    display: none !important;
  }
}
