.leaveHistory_main{
    padding-bottom: 50px;
}

.leave_table th, .annualLeavePlan_table th{
    text-transform: uppercase;
}
.leaveTable{
    margin-bottom: 4rem;
    width: 95%;
}
.leave_table td:first-child, .annualLeavePlan_table td:first-child{
    text-transform: uppercase;
}
.leaveHistory_line{
    margin: 1.3rem 0 2rem 0;
}
.leaveHistory_table{
    flex-basis: 90%;
}
/* .leave_table td{
    padding-top: 1rem  !important;
    padding-bottom: 1rem  !important;
} */
.annualLeavePlanTable{
    width: 95%;
}
/* .annualLeavePlan_table td{
    padding-top: 1rem  !important;
    padding-bottom: 1rem  !important;
} */
.leaveHistory_table tr td:first-child, .annualLeave_table tr td:first-child{
    text-transform: uppercase;
}
.leaveHistory_table tr td:nth-child(4){
    text-transform: capitalize;
}
.leaveHistory_table td, .annualLeave_table td{
    font-size: 14px !important;
}
.leaveHistory_table th, .annualLeave_table th{
    text-transform: uppercase;
    font-weight: 700 !important;
    font-size: 16px !important;
}
.annualLeave_table td:nth-child(4), .annualLeave_table td:nth-child(5){
    padding-left: 50px !important;
}