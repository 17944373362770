.fileUpload {
  position: relative;
  text-align: center;
  max-height: 30vh;
}

.fileUpload.uploaded {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.fileUpload input {
  display: none;
}

.heading {
  margin: 0;
}

.uploadIcon {
  font-size: 3rem;
}

.fileUpload label {
  grid-column: span 3;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--clr-primary);
  background-color: rgba(248, 248, 255, 1);
  padding: 3rem;
  cursor: pointer;
  margin: 0;
}

.fileUpload label.drag-active {
  background-color: var(--white);
}

.fileUpload label p {
  margin-block: 10px;
  font-size: 1.2rem;
}

.fileUpload label small {
  font-size: 0.8rem;
  color: var(--grey);
}

.uploadBtn {
  border: 0;
  background-color: inherit;
  color: var(--clr-black) !important;
  text-decoration: underline;
  cursor: pointer;
  margin-inline: auto;
}

.dragFileElement {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.preview {
  grid-column: span 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.preview svg {
  font-size: 5rem;
  color: var(--clr-primary);
}

.previewImage img {
  max-width: 60%;
  margin-inline: auto;
}

.previewText {
  font-size: 1rem;
  margin: 0;
}

@media (max-width: 480px) {
  .fileUpload.uploaded {
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .fileUpload label {
    padding: 1rem;
  }

  .fileUpload label p {
    margin-block: 10px;
    font-size: 1rem;
  }

  .preview {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.error {
  border: 1px solid red;
  width: fit-content;
  padding: .4rem 1rem;
  margin-top: 10px;
  color: var(--clr-white);
  background-color: var(--clr-red);
}

.error p {
  font-size: 1rem;
}