.container {
  height: 4.5rem;
  position: fixed;
  width: 100%;
  z-index: 200;
  background-color: var(--clr-primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px 0 0;
}

.companyInfo {
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  height: 100%;
  border-right: 3px solid #ffffff;
  padding-left: 25px;
  width: 25rem;
}

.companyLogo {
  border: 1px solid var(--clr-white);
  border-radius: 50%;
  aspect-ratio: 1;
  background-color: var(--clr-white);
  padding: 0.2rem;
  width: 50px;
  height: 50px !important;
  object-fit: contain;
}

.companyLogo img {
  border-radius: 50%;
  width: 100%;
  aspect-ratio: 1;
  width: 50px;
}

.topbarAvatar {
  height: 4rem;
  width: 4rem;
  max-width: 4rem;
  max-height: 4rem;
  overflow: hidden;
  cursor: pointer;
}
